.ph-container {
  border: 1px solid gray;
  width: 50%;
  margin: 11vh 23vw;
  border-radius: 15px 15px 0px 0px;
  min-height: 70vh;
  font-size: 1.1rem;
  position: relative;
}

.ph-bg {
  border-radius: 15px 15px 0px 0px;
  height: 30vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.ph-pic,
.ph-pic-pub {
  width: 9vw;
  height: 9vw;
  border: none;
  border-radius: 100%;
  background: linear-gradient(
    129.66deg,
    #fd0c0c 11.73%,
    #ffb800 36.2%,
    #0eab00 63.09%,
    #108cff 88.77%
  );
  display: flex;
  align-items: center;
  justify-content: center;
}

.ph-pic {
  position: absolute;
  top: 32vh;
  left: 25vw;
}

.ph-pic img,
.ph-pic-pub img {
  width: 93%;
  height: 93%;
  border-radius: 100px;
  cursor: pointer;
}

.ph-pic-pub {
  position: absolute;
  top: 30vh;
  left: 24vw;
}

.ph-pic-pub2 {
  width: 9vw;
  height: 9vw;
  position: absolute;
  top:  15vw;
  left: 23.5vw;
  border-radius: 100%;
}

.ph-pic2 {
  width: 9vw;
  height: 9vw;
  position: absolute;
  top: 15vw;
  left: 12.5vw;
  border-radius: 100%;
}

.ph-details {
  position: relative;
  top: 8vh;
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  padding: 10px 30px;
  height: max-content;
  padding-bottom: 70px;
}

.ph-name {
  font-size: 1.3rem;
  font-weight: bold;
  display: flex;
  gap: 5px;
  align-items: center;
  margin-top: 1rem;
}

.ph-headline {
  color: gray;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}

.ph-headline2 {
  color: rgb(85, 85, 85);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

#ph-mypost {
  background: #108cff;
  padding: 8px 6px;
  border-radius: 25px;
  cursor: pointer;
  transition: 0.2s;
  color: white;
  border: 2px solid transparent;
  font-size: 13px;
  display:flex;
  justify-content: center;
  /* align-items: center; */
}

#ph-mypost:hover {
  color: black;
  background: white;
  border: 2px solid #108cff;
}

.ph-follow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40%;
  margin-bottom: 10px;
}

.ph-followings,
.ph-follwers {
  cursor: pointer;
}

.ph-linkedin {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #108cff;
  margin-top: 1rem;
}

.ph-linkedin img {
  height: 30px;
}


@media(min-width:500px){
  .ph-container {
    margin-left: 5%;
    margin-top: 30px;
    /* min-height: 76vh; */
    width: 91%;
    margin-bottom: 28px;
  }
  .ph-pic2 {
    position: absolute;
    /* margin-top: 16%; */
    top: 23vh;
    left: 5%;
    height: 115px;
    width: 115px;
  }
  .ph-pic {
    position: absolute;
    top: 28%;
    left: 10vw;
    height: 115px;
    width: 115px;
  }
  .ph-pic-pub{
    position: absolute;
    top: 28%;
    left: 10vw;
    height: 115px;
    width: 115px;
  }
  .ph-pic-pub2{
    position: absolute;
    /* margin-top: 16%; */
    top: 26%;
    left: 11%;
    height: 115px;
    width: 115px;
  }

  
  .profile-data {
    margin-left: 5%;
    /* margin-top: 0.5px; */
    border: 2px solid #00000026;
    width: 91%;
    border-radius: 0 0 15px 15px;
  }
}
@media(max-width:500px){
  /* .ph-container {
    margin-left: -0.0001%;
    margin-top: 0px;
    min-height: 76vh;
    width: 99vw;
    margin-bottom: 28px;
  } */
  .ph-pic2 {
    position: absolute;
    top: 23vh;
    left: 4vw;
    height: 115px;
    width: 115px;
  }
  .ph-pic {
    position: absolute;
    top: 21vh;
    left: 7vw;
    height: 115px;
    width: 115px;
  }
  .ph-pic-pub{
    position: absolute;
    top: 21vh;
    left: 7vw;
    height: 115px;
    width: 115px;
  }
  .ph-pic-pub2{
    position: absolute;
    top: 22vh;
    left: 6vw;
    height: 115px;
    width: 115px;
  }
  .profile-data {
    margin-left: 0%;
   /* margin-left: -0.000000000010%; */
    /* border: 2px solid #00000026; */
    width: 100vw;
    /* margin-top: -0.1%; */
    border-radius: 0 0 15px 15px;
    /* position: relative; */
    
  }
  .ph-details {
    top: 6vh;
  }
  .ph-container {
    margin-inline: auto;
    margin-top: 10vh;
    min-height: 59vh;
    width: 100vw;
    position: relative;
    border-radius: 0px;
    margin-bottom: 28px;
  }
  .ph-bg {
    border-radius: 0px;
  
  }
  /* .ph-pic, .ph-pic-pub {
    left: 5vw;
    top: 27vh;
    width: 30vw;
  }
  .ph-pic img, .ph-pic-pub img {
    width: 80vw;
    height: 30vw;
    border-radius: 0px;
    cursor: pointer;
    width: 100%;
    border-radius: 100%;
  } */
  .ph-follow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
  }
  .ph-linkedin {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #108cff;
  }
  .ph-headline {
    padding: 15px 0px;
  }
  
}

@media(min-width:600px){
  .ph-container {
    margin-left: 5%;
    margin-top: 5%;
    /* min-height: 76vh; */
    width: 91%;
    /* margin-bottom: 0.1px; */
    margin-bottom:5%;
  }
  .ph-pic2{
    position: absolute;
    /* top: 29%; */
    left: 3vw;
    height:115px ;
    width: 115px;
  }
  .ph-pic-pub2{
    position: absolute;
    /* top: 29%; */
    left: 10%;
    height:115px ;
    width: 115px;
  }
  .ph-pic{
    position: absolute;
    top: 28%;
    left: 9.5vw;
    height:115px ;
    width: 115px;
  }
  .ph-pic-pub{
    position: absolute;
    top: 28%;
    left: 9.5vw;
    height:115px ;
    width: 115px;
  }

  .profile-data {
    margin-left: 5%;
    /* margin-top: 0.5px; */
    border: 2px solid #00000026;
    width: 91%;
    border-radius: 0 0 15px 15px;
  }
}
@media(min-width:700px){
  .ph-container {
    margin-left: 14vw;
    margin-top: 68px;
    /* min-height: 76vh; */
    width: 91%;
    
    margin-bottom: 40px;
  }
  .ph-pic2{
    position: absolute;
    top: 24vh;
    left: 3vw;
    height: 115px;
    width: 115px;
  }
  .ph-pic-pub2{
    position: absolute;
    top: 32%;
    left: 13%;
    height: 115px;
    width: 115px;
  }
  .ph-pic{
    position: absolute;
    top: 32%;
    left: 13vw;
    height: 115px;
    width: 115px;
  }
  .ph-pic-pub{
    position: absolute;
    top: 32%;
    left: 13vw;
    height: 115px;
    width: 115px;
  }
  .profile-data {
    margin-left: 14vw;
    /* margin-top: 0.5px; */
    border: 2px solid #00000026;
    width: 91%;
    border-radius: 0 0 15px 15px;
  }
} 
@media(min-width:768px){
  .ph-container {
    margin-left: 14vw;
    margin-top: 68px;
    /* min-height: 76vh; */
    width: 91%;
    
    margin-bottom: 40px;
  }
  .ph-pic2{
    position: absolute;
    top: 24vh;
    left: 3vw;
    height: 115px;
    width: 115px;
  }
  .ph-pic-pub2{
    position: absolute;
    top: 32%;
    left: 12%;
    height: 115px;
    width: 115px;
  }
  .ph-pic{
    position: absolute;
    top: 32%;
    left: 12vw;
    height: 115px;
    width: 115px;
  }
  .ph-pic-pub{
    position: absolute;
    top: 32%;
    left: 12vw;
    height: 115px;
    width: 115px;
  }
  .profile-data {
    margin-left: 14vw;
    /* margin-top: 0.5px; */
    border: 2px solid #00000026;
    width: 91%;
    border-radius: 0 0 15px 15px;
  }
}
@media(min-width:843px){
  .ph-container {
    margin-left: 14vw;
    margin-top: 68px;
    /* min-height: 76vh; */
    margin-bottom: 0.1px;
    width: 85%;
  }
  .ph-pic2{
    position: absolute;
    top: 20vh;
    left: 2vw;
    height: 135px;
    width: 135px;
  }
  .ph-pic-pub2{
    position: absolute;
    /* top: 200px; */
    /* top: 100pxpx; */
    left: 16%;
    height: 120px;
    width: 120px;
  }
  .ph-pic {
    position: absolute;
    /* top: 200px; */
    /* top: 100pxpx; */
    left: 15.5vw;
    height: 120px;
    width: 120px;
  }
  .ph-pic-pub{
    position: absolute;
    /* top: 200px; */
    /* top: 100pxpx; */
    left: 15.5vw;
    height: 120px;
    width: 120px;
  }
  .profile-data {
    margin-left: 14vw;
    margin-top: 0.5px;
    border: 2px solid #00000026;
    width: 85%;
    border-radius: 0 0 15px 15px;
  }
}
@media(min-width:992px){
  .ph-container {
    margin-left: 12vw;
    margin-top: 68px;
    margin-bottom: 0.1px;
    /* height: 60px; */
    width: 85%;
  }
  .ph-pic2 {
    position: absolute;
    top: 20vh;
    left: 2vw;
    height: 120px;
    width: 120px;
  }
  .ph-pic-pub2{
    position: absolute;
    /* top: 195px; */
    /* top: 32.5%; */
    left: 15%;
    height: 120px;
    width: 120px;
  }
  .ph-pic {
    position: absolute;
    /* top: 195px; */
    /* top: 32.5%; */
    left: 15vw;
    height: 120px;
    width: 120px;
  }
  .ph-pic-pub{
    position: absolute;
    /* top: 195px; */
    /* top: 32.5%; */
    left: 15vw;
    height: 120px;
    width: 120px;
  }
  .profile-data {
    margin-left: 12vw;
    margin-top: 0.5px;
    border: 2px solid #00000026;
    width: 85%;
    border-radius: 0 0 15px 15px;
  }
}
@media screen and (min-width: 1190px) {
  .ph-container {
    /* margin-left: 12.5%; */
    margin-left: 12vw;
    margin-top: 68px;
    /* min-height: 76vh; */
    margin-bottom: 0.1px;
    width: 65%;
  }
  .ph-pic2{
    position: absolute;
    top: 20vh;
    /* top: 31.5%; */
    left: 1.5vw;
    height: 130px;
    width: 130px;
  }
  .ph-pic-pub2{
    position: absolute;
    /* top: 32vh; */
    /* top: 31.5%; */
    left: 15%;
    height: 120px;
    width: 120px;
  }
  .ph-pic{
    position: absolute;
    /* top: 32vh; */
    /* top: 31.5%; */
    left: 15vw;
    height: 120px;
    width: 120px;
  }
  .ph-pic-pub{
    position: absolute;
    /* top: 32vh; */
    /* top: 31.5%; */
    left: 15vw;
    height: 120px;
    width: 120px;
  }
  .profile-data {
    margin-left: 12vw;
    /* margin-top: 0.5px; */
    border: 2px solid #00000026;
    width: 65%;
    border-radius: 0 0 15px 15px;
  }
}
@media screen and (min-width: 1400px) {
  .ph-container {
    /* margin: 1vh 11vw; */
    /* margin-right: 500px; */
    /* margin-left: 5px; */
    /* min-height: 76vh; */
    margin-top: 68px;
    width: 64%;
    /* margin-left: 22%; */
    margin-left: 15vw;
    /* margin-top: 100px; */
    /* margin-bottom: 100px; */
    /* margin-right: auto; */
    margin-bottom: 0.0001px;
  }
  .ph-pic2 {
    position: absolute;
    top: 20vh;
    left: 1.5vw;
    height: 120px;
    width: 120px;
  }
  .ph-pic-pub2{
    position: absolute;
    /* top: 31.5%; */
    left: 24%;
    height: 120px;
    width: 120px;
  }
  .ph-pic {
    position: absolute;
    /* top: 31.5%; */
    left: 24vw;
    height: 120px;
    width: 120px;
  }
  .ph-pic-pub{
    position: absolute;
    /* top: 31.5%; */
    left: 24vw;
    height: 120px;
    width: 120px;
  }
  .profile-data {
    margin-left: 15vw;
    /* margin-top: -5.2%; */
    /* margin-top: none; */
    border: 2px solid #00000026;
    width: 64%;
    border-radius: 0 0 15px 15px;
}
}
/* @media screen and (max-width: 1190px) {
  .ph-container {
    margin: 11vh 11vw;
    min-height: 76vh;
    width: 719px;
  }
   .ph-pic {
    position: absolute;
    top: 31vh;
    left: 13vw;
  }
  .profile-data {
    margin-left: 23.5%;
    margin-top: -8%;
    border: 2px solid #00000026;
    width: 53.25%;
    border-radius: 0 0 15px 15px;
}
} */


/* @media screen and (min-width: 1500px) {
  .ph-pic2 {
    width: 9vw;
    height: 9vw;
    position: absolute;
    top: 15vw;
    left: 24.5vw;
    border-radius: 100%;
  }
  .ph-pic-pub {
    position: absolute;
    top: 32vh;
    left: 25vw;
  }
  .ph-pic-pub2 {
    left: 24vw;
  }
} */


