
@font-face {
    font-family: 'Product Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans'), local('OpenSans'), url(https://fonts.gstatic.com/s/productsans/v5/HYvgU2fE2nRJvZ5JFAumwegdm0LZdjqr5-oayXSOefg.woff2) format('woff2');
}
body{
    font-family: Product Sans;
    

}
.information{
    margin-bottom: 10px;
}
.two_boxes{
    display: flex;
    flex-direction: row;

}
.container_overview{
    margin-top: 75px;
    /* margin-left: 330px; */
    overflow-y: hidden;
}
.boxes{
    /* display: flex; */
    /* flex-direction: row; */
    width: 370px;
    /* border: 1px solid black; */
    height: 120px;
    padding: 20px;
    margin: 5px;
    border-radius: 5px;
    box-shadow: 4px 3px 8px 1px #96969692;

}
.boxes img{
    position: relative;
    height: 80px;
    width: 80px;
    top: -38px;


}
.inside-box{
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
    padding-left: 10px;
}
.data{
    font-size: 38px;

}
select{
    height: 30px;
    width: 60px;
    text-align: center;
    margin-left: 20px;
}
textarea{
    border-radius: 5px;
    border: none;
    border-radius: 5px;
    box-shadow: 4px 3px 8px 1px #96969692;
    padding: 10px;
    margin: 10px ;
    margin-bottom: 5px;
    font-family: Product Sans;

}
.Send_notifs{
    width: 370px;
    /* border: 1px solid black; */
    height: 200px;
    padding: 20px;
    margin: 5px;
    /* border-radius: 5px; */
    /* box-shadow: 4px 3px 8px 1px #96969692; */
}
.reviews{
    width: 370px;
    /* border: 1px solid black; */
    height: 300px;
    padding: 20px;
    margin: 5px;
    border-radius: 5px;
    box-shadow: 4px 3px 8px 1px #96969692;
}
.viewall{
    color:#108CFF ;
    border: none;
    background-color: none;
}
.top_of_review{
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}
.Notifs_Feedbacks{
    display: flex;
    flex-direction: row;
}
.post{
    /* margin-top: -10px; */
    background-color: black;
    color: white;
    width:100px ;
    padding: 2px ;
    border-radius: 5px;
    
}
.post_button{
    display: flex;
    justify-content: right;
}
.reviewer{
    width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
    margin-top: 15px;
}
.reviews_feedback{
    margin-top: 10px;
}
.arrow{
    height: 20px;
    width: 20px;
    /* left: -100px; */
}
.user{
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
}
@media (max-width: 500px){
    .two_boxes{
        display: flex;
        flex-direction: column;
    
    }
    .Notifs_Feedbacks{
        display: flex;
        flex-direction: column;
    }
    .container_overview{
        margin-top: 90px;
        margin-left: 10px;
    }
    .boxes{
        width: 95%;
    }
    .reviews{
        width: 95%;
        margin-bottom: 100px;
    }
    .Send_notifs{
        width: 95%;
        margin-bottom: 120px;
    }
    .reviewer{
        width: 95%;
    }

}
@media (min-width: 500px){
    .two_boxes{
        display: flex;
        flex-direction: column;
    
    }
    .Notifs_Feedbacks{
        display: flex;
        flex-direction: column;
    }
    .container_overview{
        margin-left: 50px;
    }
    .boxes{
        width: 400px;
    }
    .reviews{
        width: 400px;
        margin-bottom: 100px;
    }
    .Send_notifs{
        width: 270px;
        margin-bottom: 120px;
    }
    .reviewer{
        width: 330px;
    }

}
@media (min-width: 600px){
    .container_overview{
        margin-left: 20px;
    }
    .boxes{
        width: 270px;
    }
    .reviews{
        width: 270px;
    }
    .Send_notifs{
        width: 270px;
    }
    .reviewer{
        width: 220px;
    }
    .two_boxes{
        display: flex;
        flex-direction: row;
    
    }
    .Notifs_Feedbacks{
        display: flex;
        flex-direction: row;
    }

}
@media (min-width: 700px){
    .container_overview{
        margin-left: 100px;
    }
    .boxes{
        width: 270px;
    }
    .reviews{
        width: 270px;
    }
    .Send_notifs{
        width: 270px;
    }
    .reviewer{
        width: 220px;
    }
    .two_boxes{
        display: flex;
        flex-direction: row;
    
    }
    .Notifs_Feedbacks{
        display: flex;
        flex-direction: row;
    }

}
@media (min-width: 768px){
    .container_overview{
        margin-left: 120px;
    }
    .boxes{
        width: 300px;
    }
    .reviews{
        width: 300px;
    }
    .Send_notifs{
        width: 300px;
    }
    .reviewer{
        width: 250px;
    }
    

}

@media (min-width: 998px){
    .container_overview{
        margin-left: 210px;
    }
    .boxes{
        width: 300px;
    }
    .reviews{
        width: 300px;
    }
    .Send_notifs{
        width: 300px;
    }
    .reviewer{
        width: 250px;
    }

}
@media (min-width: 1190px){
    .container_overview{
        margin-left: 330px;
    }
    .boxes{
        width: 370px;
    }
    .reviews{
        width: 370px;
    }
    .Send_notifs{
        width: 370px;
    }
    .reviewer{
        width: 300px;
    }

}