* {
  border: 0;
}

.booking-slot-section {
  width: 70%;
  height: 100vh;
  /* background-color: rgba(224, 224, 224, 0.584); */
  display: flex;
  justify-content: center;
}

.book-content {
  width: 90%;
  height: 100%;
  /* background-color: rgb(118, 104, 104); */
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.header-slotbook {
  font-size: 2.1rem;
  font-weight: 600;
  padding-left: 2rem;
}

.filter-options-booking {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.f-slot {
  width: 120px;
  height: 30px;
  color: white;
  background: #25ced1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.f-slot-2 {
  background-color: gray;
}

.f-slot-3 {
  background: white;
  color: black;
  /* border: 1px solid gray; */
}

.slot-complete-list {
  width: 97%;
  height: 100%; /* background-color: lightblue; */
  margin-top: 20px;
}

.slot-daywise {
  width: 100%;
  height: auto;
  margin-top: 0px;
  display: flex;
}

.slot-datewise {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: auto;
}

.day-name {
  font-size: 18px;
  color: black;
}

.slot-scheduled-time {
  font-size: 18px;
  color: black;
}

.amount-slot {
  font-size: 18px;
  color: black;
}

.list-1 {
  height: 70px;
  width: 90%;
  border: 2px solid #00000015;
  border-radius: 15px;
  /* background: linear-gradient(
    90deg,
    #ebefff 16.64%,
    rgba(251, 241, 247, 0.5) 63.09%,
    rgba(255, 152, 120, 0) 109.53%
  ); */
}

.button-2-book {
  display: flex;
  width: 20%;
  height: auto;
  background: white;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

.headers-slot {
  display: flex;
  justify-content: space-around;
  font-weight: 400;
  font-size: 20px;
  width: 80%;
  margin-left: 1.5vw;
  margin-bottom: 5px;
  color: gray;
}

.slot-1-book {
  width: 100%;
  height: auto;
  /* background-color: cyan; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 5px;
  /* position: relative; */
}

.slot-complete-list button {
  background-color: #108cff;
  color: white;
  border-radius: 8px;
  border: none;
  height: 40px;
  width: 80px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
}
.list-1 {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@media screen and (max-width: 500px) {
  .booking-slot-section {
    width: 70%;
    height: 100vh;
    /* background-color: rgba(224, 224, 224, 0.584); */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10vh;
  }
  .header-slotbook {
    text-align: center;
    padding-left: 0px;
    margin-top: 20px;
  }


  .slot-complete-list {
    width: screen.width;
    height: 100%; /* background-color: lightblue; */
    margin-top: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 200px;
    padding-bottom: 200px;
  }
  .headers-slot {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 70px;
    margin-left: -13vw;
    margin-bottom: 5px;
    font-size: 14px;
  }
  .slot-1-book {
    width: 90vw;
    height: auto;
    /* background-color: cyan; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-bottom: 10px;
  }

  .day-name {
    font-size: 12px;
    max-width: 120px;
  }

  .slot-scheduled-time {
    font-size: 14px;
  }

  .list-1 {
    border-radius: 10px;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .slot-complete-list button {
    background-color: #108cff;
    border-radius: 10px;
    border: none;
    height: 30px;
    width: 60px;
    font-weight: 600;
    cursor: pointer;
    font-size: 13px;
    margin-left: 8px;
  }
  .amount-slot {
    font-size: 12px;
  }

  .slots-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

}
