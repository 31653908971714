.pp-container {
	margin: 120px auto;
	width: 66%;
	font-size: 1.07rem;
	word-spacing: 2px;
	letter-spacing: 0.5px;
}

h1 {
	font-size: 1.8rem;
	font-weight: bold;
}

.pp-main-content {
	margin-top: 30px;
}

.pp-head-para {
	margin-top: 30px;
}
.pp-head-para h2 {
	margin-bottom: 15px;
}

@media screen and (max-width: 600px) {
  .pp-container {
    width: 85%;
  }
} 
