.tb-container {
  background: transparent;
  backdrop-filter: blur(10px);
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-bottom: 1px solid rgb(193, 193, 193);
  padding: 13px 3.5vw;
  z-index: 10;
}

.tb-container div {
  display: flex;
  align-items: center;
  gap: 10px;
}

h2 {
  font-size: 1.4rem;
  font-weight: 600;
}

#create-post {
  background: #108cff;
  padding: 7px 10px 7px 10px;
  border-radius: 25px;
  font-size: 18px;
  cursor: pointer;
  transition: 0.2s;
  font-weight: 500;
  color: white;
  border: 2px solid transparent;
}

#create-post:hover {
  border: 2px solid #108cff;
  background: white;
  color: black;
}
@media screen and (min-width:700px) and (max-width:768px) {
  .tb-container{
    display: flex;

  
}
}
@media screen and (max-width:768px){
  .tb-container{
    visibility: visible;
  }
}