body {
  font-family: 'Ubuntu';
}

.dash-main {
  display: flex;
  margin-top: 18px;
  margin-left: 23vw;
  width: 90%;
}

.editor-ids {
  justify-content: center;
}

.userdash {
  height: 900px;
  width: 500px;
  display: flex;
  box-shadow: 0px 0px 5px rgba(128, 128, 128, 0.511);
  justify-content: center;
  position: absolute;
  top: 0;
  position: fixed;
  z-index: 1;
  gap: 100px;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
}

.dashbg {
  height: 277px;
  position: absolute;
  top: 0;
  width: 100%;
  background-image: url('..//..//images//rect.png');
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px 0px 10px 10px;
  display: flex;
  justify-content: center;
  align-items: end;
}

.rounded-img {
  height: 194px;
  width: 194px;
  border-radius: 50%;
  background-color: white;
  transform: translate(0, 50%);
  box-shadow: 1px 0px 5px rgb(161, 161, 161);
}

.info-user {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 70%;
  gap: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 300px;
}

.contact-details {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  align-items: flex-start;
  gap: 10px;
}

.email-d,
.phone-d,
.upi-d {
  display: flex;
  gap: 10px;
}

.Editor-dashboard {
  display: flex;
  justify-content: center;
}

.Editor-dashboard button {
  padding: 10px;
  border-radius: 10px;
  border: none;
  width: 194px;
  height: 56px;
  color: white;
  font-size: 15px;
  cursor: pointer;
  background-color: rgba(37, 206, 209, 1);
  transition: 0.2s;
}

.Editor-dashboard button:hover {
  background-color: rgb(29, 156, 158);
}

.dash-right {
  display: flex;
  flex-direction: column;
  /* height: 850px; */
  width: calc(100% - 500px);
}
.dash-right-2 {
  width: 50%;
}

.dash-nav {
  display: flex;

  gap: 50px;
  margin-left: 50px;
  margin-right: 50px;
  height: 100px;
  align-items: center;
  position: relative;
  font-weight: 600;
  font-size: 20px;
}

.chosen {
  border-bottom: 2px solid rgb(156, 35, 5);
}

/* /-----------------Navbar popup for mob view-------------/  */
.side-nav-mob {
  display: none;
}

/* /-----------------/  */
.login-out {
  position: absolute;
  right: 10px;
  border: 1px solid black;
  padding: 10px;
  border-radius: 20px;
  width: 100px;
  display: flex;
  justify-content: center;
  transition: 0.2s;
  cursor: pointer;
}

.login-out:hover {
  background-color: black;
  color: white;
}

.partition-d {
  width: 90%;
  height: 3px;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  background: rgb(168, 168, 168);
}

.header-dash {
  display: flex;
  position: relative;
  top: 5vh;
  left: 10vw;
  font-size: 1.8rem;
  margin-bottom: 2vh;
}

.schedules-dash {
  position: relative;
  top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 65%;
  align-items: center;
  background: linear-gradient(
    181deg,
    rgba(50, 119, 224, 0) -16.61%,
    rgba(216, 234, 255, 0.51) 42.93%,
    rgba(174, 197, 255, 0) 103.71%
  );
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 20px;
}

.buttons-dash {
  display: flex;
  gap: 20px;
}

.buttons-dash button {
  border-radius: 5px;
  padding: 10px;
  background: white;
  border: 0.5px solid black;
  margin-bottom: 20px;
}
.buttons-dash button:hover {
  cursor: pointer;
  background: rgb(202, 202, 202);
}

.today {
  border: 0.5px solid gray;
}

.btns-dash {
  display: flex;
  gap: 10px;
  border: none;
}

.btns-dash button {
  border: none;
}

.slot-dash {
  display: flex;
  background-color: rgba(10, 64, 255, 0.08);
}

.days-dash {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
 
  width: 200px;
  padding: 50px;
  background-color: rgba(10, 64, 255, 0.08);
  border-radius: 15px;

  transition: 0.2s;
}

.days {
  display: flex;
  gap: 80px;
}

.slots-dash {
  display: flex;
  flex-direction: column;
  width: 220px;
  padding: 50px;
  gap: 35px;
  background-color: rgba(255, 255, 255, 0.48);
}

@media screen and (max-width: 500px) {
  .slots-dash h3 {
    width: 250px;
  }
  .dash-main {
    margin-top: 45px;
    min-height: 100vh;
    margin-bottom: 80px;
  }
  .info-dash-table {
    position: relative;
    top: 80px;
    left: 55px;
    display: flex;
    justify-content: center;
    font-size: 15px;
  }
  .slot-dash {
    margin-right: 200px;
  }
  .slots-dash {
    display: flex;
    flex-direction: column;
    width: 50px;
    gap: 35px;
    padding: 0px;
    padding-top: 20px;
    margin-left: 10px;
  }

  .schedules-dash {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 246%;
    margin-left: -165px;
    align-items: center;
    background: linear-gradient(
      181deg,
      rgba(50, 119, 224, 0) -16.61%,
      rgba(216, 234, 255, 0.51) 42.93%,
      rgba(174, 197, 255, 0) 103.71%
    );
    padding-top: 20px;
    padding-bottom: 20px;
    margin-right: 50px;
  }
  .days-dash {
    gap: 20px;
    height: 130vw;
    background-color: rgba(255, 255, 255, 0.48);
    width: 137px;
    padding: 20px;
    margin-left: 20px;
    background-color: rgba(10, 64, 255, 0.08);
    border-radius: 15px;

    transition: 0.2s;
    margin-left: 20px;
  }

  .header-dash {
    left: 0vw;
    text-align: center;
    padding-inline: 10px;
    top: 80px;
  }
  .dash-right-2 {
    width: 100%;
  }
}
@media screen and (max-width: 395px) {
  .slots-dash h3 {
    width: 250px;
  }
  .dash-main {
    margin-top: 45px;
    min-height: 100vh;
    margin-bottom: 80px;
  }
  .info-dash-table {
    position: relative;
    top: 80px;
    left: 55px;
    display: flex;
    justify-content: center;
    font-size: 15px;
  }
  .slot-dash {
    margin-right: 200px;
  }
  .slots-dash {
    display: flex;
    flex-direction: column;
    width: 50px;
    gap: 35px;
    padding: 0px;
    padding-top: 20px;
    margin-left: 10px;
    
  }

  .schedules-dash {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 200%;
    margin-left: -85px;
    align-items: center;
    background: linear-gradient(
      181deg,
      rgba(50, 119, 224, 0) -16.61%,
      rgba(216, 234, 255, 0.51) 42.93%,
      rgba(174, 197, 255, 0) 103.71%
    );
    padding-top: 20px;
    padding-bottom: 20px;
    margin-right: -85px;
  }
  .days-dash {
    gap: 20px;
    height: 150vw;
    background-color: rgba(255, 255, 255, 0.48);
    width: 115px;
    padding: 20px;
    margin-left: 4px;
    background-color: rgba(10, 64, 255, 0.08);
    border-radius: 15px;
    margin-top: 15px;
    transition: 0.2s;
    margin-left: 9vh;
  }

  .header-dash {
    left: 0vw;
    text-align: center;
    padding-inline: 10px;
    top: 80px;
  }
  .dash-right-2 {
    width: 100%;
  }
  .pushslot{
    margin-right: 20px;
    gap: 1px;
  }
}

.slot-1,
.slot-2,
.slot-3,
.slot-4,
.slot-5,
.slot-6,
.slot-7 {
  display: flex;
  gap: 40px;
}

.slot-1 select,
.slot-2 select,
.slot-3 select,
.slot-4 select,
.slot-5 select,
.slot-6 select,
.slot-7 select {
  width: 100px;
  outline: none;
  height: 25px;
  border: none;
  border-radius: 4px;
}

.pushslot {
  transition: 0.2s;

  padding: 0px 10px 0px 10px;
  border-radius: 50%;
  border: none;
  background: rgba(45, 138, 255, 1);
  color: white;
  font-size: 20px;
  box-shadow: 0px 1px 2px black;
  cursor: pointer;
}

.slot-pop {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.pushslot:hover {
  transform: scale(110%);
}



.popup-of-slots {
  position: absolute;
  left: auto;
  right: auto;
  display: flex;
  height: 400px;
  width: 400px;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
}

.show-slot-list {
  border: none;
}

.all-slots {
  height: 94%;
  gap: 28px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 37px;
  overflow-y: scroll;
  align-items: center;
}



.hideelem {
  display: none;
}

.monday,
.tuesday,
.wednesday,
.thursday,
.friday,
.saturday,
.sunday {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cross {
  z-index: 21;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  height: 30px;
  width: 30px;
  background-color: black;
  right: 20px;
  border-radius: 50%;
  cursor: pointer;
}

.line31 {
  width: 60%;
  transform: rotate(45deg);
  height: 1px;
  background-color: white;
}

.line32 {
  width: 60%;
  transform: rotate(-45deg);
  height: 1px;
  background-color: white;
}

.days-partition {
  width: 100%;
  height: 1px;
  display: flex;
  background-color: rgb(10, 10, 10);
}

.Save-btn {
  position: relative;
  top: 150px;
  display: flex;
  justify-content: center;
}

.save-det {
  width: 100px;
  height: 50px;
  border: none;
  background-color: rgb(66, 178, 66);
  color: white;
  border-radius: 15px;
}

/* /-----------------------------------ACCOUNT SECTION----------/  */
.social-dash {
  position: relative;
  top: 40px;
  left: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  max-width: 60%;
}

.social-details {
  width: 90%;
  height: 300px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;
  flex-wrap: wrap;
  justify-content: center;
}

.linkedin-dash,
.gmail-dash,
.phone-dash,
.upi-id-dash {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.linkedin-dash input,
.gmail-dash input,
.phone-dash input,
.upi-id-dash input {
  font-size: 18px;
  font-weight: 600;
  background: transparent;
  border-bottom: 2px solid rgb(0, 0, 0);
  border-radius: 0;
}

.fa-phone {
  font-size: 40px;
}

.fa-credit-card {
  font-size: 40px;
}

.editor-ids {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 20px;
  gap: 50px;
  margin-right: 10vw;
}

@media screen and (max-width: 500px) {
  .slot-1 select,
  .slot-2 select,
  .slot-3 select,
  .slot-4 select,
  .slot-5 select,
  .slot-6 select,
  .slot-7 select {
    width: 100px;
    outline: none;
    height: 25px;
    border: none;
    border-radius: 4px;
  }

  .social-dash {
    position: relative;
    top: 40px;
    left: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    max-width: 100%;
  }

  .editor-ids {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 50px;
    left: 0px;
    gap: 20px;
    margin-right: 0px;
  }
}

.edit-input {
  font-weight: 700;
  font-size: 15px;
  cursor: pointer;
  width: 100px;
  height: 50px;
  background-color: rgb(0, 0, 0);
  border: none;
  border-radius: 17px;
  color: white;
}

.delete-account-dash {
  width: 120px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

@media only screen and (max-width: 900px) {
  .social-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    height: 400px;
    width: 100%;
  }
}

/* /---------------/ /------------------------------------Earning-------------------/  */
.earning-details {
  height: 150px;
  width: 100px;
  position: relative;
  top: 10px;
  width: 80%;
  gap: 100px;
  display: flex;
  color: white;
}

.total-mentee {
  width: 250px;
  height: 150px;
  background-color: #ffb8004d;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  padding: 20px;
  border-radius: 6px;
  color: black;
  margin-left:-17px ;
}

.earnings-dash {
  width: 250px;
  height: 150px;
  background-color: #0eab004d;
  padding: 20px;
  color: rgb(12, 55, 100);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  padding: 20px;
  border-radius: 6px;
}

.info-dash-table {
  position: relative;
  top: 50px;
  display: flex;
  justify-content: start;
}

.info-dash-table table {
  width: 80%;
  border: 1px solid rgba(37, 206, 209, 0.56);
  border-radius: 5px;
}

.info-dash-table table thead th {
  font-size: 1.2rem;
  padding: 10px;
  background-color: rgba(37, 206, 209, 0.56);
  border-radius: 15px;
}

.info-dash-table table tbody {
  text-align: center;
}

.info-dash-table table tbody td {
  border-bottom: 0.2px solid gray;
  padding: 10px;
}

@media only screen and (max-width: 750px) {
  .earning-dash {
    margin-left: 0px;
    margin-bottom: 50px;
  }

  .earning-details {
    gap: 40px;
    height: 130px;
  }

  .total-mentee {
    width: 250px;
    height: 100px;
    display: flex;
    flex-direction: row;
  }

  .earnings-dash {
    width: 250px;
    height: 100px;
    display: flex;
    flex-direction: row;
  }

  .earning-dash h1 {
    font-size: 30px;
    
  }

  #subtopics {
    margin-top: 15vw;
  }

  .info-dash-table {
    position: relative;
    top: 80px;
    left: 55px;
    display: flex;
    justify-content: center;
    font-size: 15px;
  }

  .info-dash-table table {
    padding: 10px;
    margin-left: 12px;
  }

  .info-dash-table table thead th {
    font-size: medium;
    font-weight: 200;
    padding: 2px 8px;
    background-color: rgba(37, 206, 209, 0.56);
    border-radius: 10px;
  }

  .info-dash-table table thead tr {
    margin-bottom: 40px;
  }
}

/* /-----------------------/ /-------------------------Bookings---------------------------/  */
.session-dash-info {
  width: 100%;
  height: 100vh;
  position: relative;
  top: 50px;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  border-right: 1px solid #00000026;
}

.heading-session-status {
  display: flex;
  gap: 40px;
  font-size: 17px;
  border-bottom: 1px solid #00000026;
  width: 100%;
  padding: 10px;
  padding-left: 22px;
  font-weight: 700;
}

.heading-session-status div {
  padding: 5px;
  padding-inline: 0px;
  transition: 0.2s;
}

.up {
  border-bottom: 5px solid transparent;
  border-image: linear-gradient(to right, #fd0c0c, #ffb800, #0eab00, #108cff) 1;
}
.session-list-dash {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-block: 1px solid #00000026;
}

.partition-session {
  width: 80%;
  height: 1px;
  background-color: gray;
}

.individual-session {
  width: 100%;
  

  align-items: center;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: space-between;
  padding: 10px;
  padding-inline: 30px;
  cursor: pointer;
}



.meet-rate {
  padding: 2vw 4vw;
  border: 2px solid black;
  border-radius: 15px;
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1vw;
  color: black;
  font-size: 1.1rem;
}

@media only screen and (max-width: 1400px) {
  .dash-right {
    display: flex;
    gap: 40px;
    font-size: 17px;
    width: 100%;
    padding: 10px;
    margin-inline: -15vw;
    padding-left: 22px;
    font-weight: 700;
  }
  .session-dash-info {
    width: 75%;
    height: 100vh;
    position: relative;
    top: 50px;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    border-right: 1px solid #00000026;
    margin-inline: 0vw;
  }
  .meet-rate {
    margin-inline: 0vw;
    text-align: center;
    padding: 2vw 4vw;
    border: 2px solid black;
    border-radius: 15px;
    width: 55%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1vw;
    color: black;
    font-size: 1.1rem;
  }
}
@media only screen and (max-width: 1100px) {
  .dash-right {
    display: flex;
    gap: 40px;
    font-size: 17px;
    width: 100%;
    padding: 10px;
    margin-inline: -15vw;
    padding-left: 22px;
    font-weight: 700;
  }
  .session-dash-info {
    width: 100%;
    height: 100vh;
    position: relative;
    top: 50px;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    border-right: 1px solid #00000026;
    margin-inline: 0vw;
  }
  .meet-rate {
    margin-inline: 0vw;
    position: absolute;
    right: 30vw;
    top: 45vw;
    padding: 2vw 4vw;
    border: 2px solid black;
    border-radius: 15px;
    width: 55%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1vw;
    color: black;
    font-size: 1.1rem;
  }



}
@media only screen and (max-width: 800px) {
  .dash-right {
    display: flex;
    gap: 40px;
    font-size: 17px;
    width: 100%;
    padding: 10px;
    margin-inline: -15vw;
    padding-left: 22px;
    font-weight: 700;
    margin-inline: 4vw;
  }
  .session-dash-info {
    width: 100%;
    height: 100vh;
    position: relative;
    top: 50px;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    border-right: 1px solid #00000026;
    margin-inline: 0vw;
  }
  .meet-rate {
    margin-inline: -10vw;
    position: absolute;
    right: 30vw;
    top: 65vw;
    padding: 2vw 4vw;
    border: 2px solid black;
    border-radius: 15px;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1vw;
    color: black;
    font-size: 1.1rem;
  }
}
.meet-rate-input {
  padding: 0.4vw 1vw;
  border-radius: 10px;
  font-size: 1.07rem;
}

.update-meet-rate-btn {
  font-size: 1.04rem;
  border-radius: 10px;
  background: green;
  color: white;
  padding: 0.4vw 1vw;
  cursor: pointer;
  border: none;
}

.update-meet-rate-btn:hover {
  opacity: 0.8;
}
/* /----------------------------/  */
.view-on-mob {
  display: none;
}

.meets-head {
  margin-left: 20px;
}

@media screen and (max-width: 500px) {
  .meet-rate-input {
    max-width: 50vw;
  }
  .meet-rate {
    position: absolute;
    right: 5vw;
    top: 65vh;
    width: 90%;
    padding: 10px 0px;
    margin: auto;
    margin-top: 10px;
  }

  .session-dash-info {
    width: 100%;
    height: 0px;
    position: relative;
    top: 50px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-left: -18px;
    margin-right: auto;
    padding: 0px;
  }

  .session-dash-info h2 {
    margin-left: 10px;
    margin-top: 30px;
  }
  .heading-session-status {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 35px;
    font-size: 18px;
    padding: 0px;
    border-bottom: 0px;
  }

  .individual-session {
    width: 88vw;
    margin: 10px;
    align-items: center;
    display: flex;
    position: relative;
    flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
  }

  .session-list-dash {
    margin-inline: 5px;
    overflow-y: auto;
    height: 80%;
  }

  .meets-head {
    margin-left: 20px;
  }
}
@media screen and (max-width: 285px){

  .heading-session-status {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 35px;
    font-size: 14px;
    padding: 0px;
    border-bottom: 0px;
  }
}

@media only screen and (max-width: 1400px) {
  .view-on-mob {
    display: block;
  }

  .userdash {
    display: none;
  }

  .dash-right {
    left: 0px;
    width: 100%;
  }
  .partition-session {
    width: 60%;
  }
}

/* /--------------------navbar--------------/ */
@media only screen and (max-width: 1000px) {
  .view-on-mob {
    display: none;
  }

  .hide-on-mob {
    display: none;
  }

  .hamburg-dash {
    z-index: 6;
    display: flex;
    flex-direction: column;
    gap: 1px;
    position: absolute;
    right: 5px;
  }

  .line1-dash,
  .line2-dash,
  .line3-dash {
    width: 20px;
    content: '';
    height: 0.5px;

    position: relative;
    margin-bottom: 4px;
    top: 4px;
    border: 1px solid rgb(0, 0, 0);
    background: black;
    border-radius: 2px;
    transition: 0.4s;
    cursor: pointer;
  }

  .line1-dash {
    width: 60%;
    position: relative;
  }

  .line3-dash {
    width: 60%;
    position: relative;
    left: 0%;
  }

  .side-nav-mob {
    display: flex;
    position: absolute;
    height: 100%;
    width: 100px;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    padding: 80px;
    font-size: 20px;
    z-index: 4;
    cursor: pointer;
    background: #1fa9abc0;
    backdrop-filter: blur(5px);
    transition: 0.5s;
  }
}

.display {
  transform: translateX(100%);
}


.today:hover,
.show-slot-list:hover,
.btns-dash button:hover {
  border: 1px solid black;
}


.btns-dash button:hover {
  border: 1px solid black;
}



@media only screen and (min-width: 1400px) {
  .schedules-dash {
    width: 86%;
    margin-left: 3vw;
  }

  .header-dash {
    left: 8vw;
  }
}

@media only screen and (max-width: 500px) {
  .popup-of-slots {
    width: 340px;
  }
}
