.edit-input {
  background-color: #108cff;
  padding: 10px 20px;
  cursor: pointer;
  color: white;
  border-radius: 40px;
  height: auto;
}

.edit-input:hover {
  background-color: #108cff98;

  border: 4px solid #2e4c691d;
  /* box-shadow: 0px 8px 20px 0px #25ced126; */
}

.edit-pass {
  background: #108cff;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border: 4px solid white;
  font-weight: 700;
  font-size: 15px;
  cursor: pointer;
  border-radius: 40px;
}

.edit-pass:hover {
  background-color: #108cff98;
}

.social-details {
  text-wrap: wrap;
  background: transparent;
  /* padding: 20px; */
  background: linear-gradient(
    179.81deg,
    #effcff 0.17%,
    rgba(247, 239, 255, 0.5) 57.42%,
    rgba(240, 255, 180, 0) 114.68%
  );
}

/* styles.css */

/* Equivalent styles for Tailwind CSS classes */
.pt-10vh {
  padding-top: 10vh;
}

.h-100vh {
  height: 100vh;
}

.border-r-2 {
  border-right-width: 2px;
}

.md\:border-0 {
  @media (min-width: 768px) {
    border: none;
  }
}

.md\:pt-6vh {
  @media (min-width: 768px) {
    padding-top: 6vh;
  }
}

/* Update LinkedIn input field */
#linkedin,
#upi {
  background-color: #f3f4f6; /* Light gray background */
  padding: 10px;
  border-radius: 8px;
  width: 100%; /* Full width */
  max-width: 400px; /* Max width to limit expansion */
}


/* Additional styles for media query */
@media (max-width: 500px) {
  .ml--35px {
    margin-left: -125px;
  }
  .dash-main{
    margin-top: 70px;
  }
  .textarea{
    width:90vw
  }

  /* Overriding style for media query */
  .ml--35px\@media\ \(max-width:\ 500px\) {
    margin-left: 0px;
  }
}
@media (min-width: 500px){
  .ml--35px {
    margin-left: -135px;
  }
  .dash-main{
    margin-top: 75px;
  }
  .textarea{
    width:85vw
  }
}

@media (min-width: 768px){
  .ml--35px {
    margin-left: -115px;
  }
  .dash-main{
    margin-top: 45px;
  }
  .textarea{
    width:60vw
  }
}
@media (min-width: 800px){
  .ml--35px {
    margin-left: 50px;
  }
  .dash-main{
    margin-top: 40px;
  }
  .textarea{
    width:65vw
  }
}
@media (min-width: 992px){
  .ml--35px {
    margin-left: 50px;
  }
  .dash-main{
    margin-top: 40px;
  }
  .textarea{
    width:50vw
  }
}
@media (min-width: 1190px){
  .ml--35px {
    margin-left: 50px;
  }
  .dash-main{
    margin-top: 40px;
  }
  .textarea{
    width:40vw
  }
}
@media (min-width: 1400px){
  .ml--35px {
    margin-left: -135px;
  }
  .dash-main{
    margin-top: 70px;
  }
}

@media only screen and (max-width: 1400px) {
  .dash-main {
    
    width: 100%;
    
    
  }

  .editor-ids {
    justify-content: normal;
  }

  .social-details {
    width: 150%;
  }
}
@media (min-width: 1400px){
  .ml--35px {
    margin-left: 40px;
  }
  .dash-main{
    margin-top: 70px;
    /* width: 100vw; */
  }
  .textarea{
    width:40vw
  }
}

