 .aboutpg-container {
    max-width: 1200px;
    margin: 40px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  

  .aboutpg-hero {
    position: relative;
    background-image: url(banner.jpg);
    background-size: 100% 400px;
    background-position: 0% 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    opacity: 0.7;
  }

.aboutpg-hero::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: -1;
  }

  .aboutpg-hero h1 {
    font-size: 36px;
    margin-bottom: 10px;
  }
  
  .aboutpg-hero p {
    font-size: 18px;
    margin-bottom: 20px;
  }
   
  
  .aboutpg-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    margin-top: 40px;
  }
  
  .aboutpg-box {
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .aboutpg-box h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .aboutpg-box p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .aboutpg-box img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .aboutpg-section {
    margin-top: 40px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .aboutpg-section h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .aboutpg-section p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .aboutpg-section button {
    background-color: #4CAF50;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .aboutpg-section button:hover {
    background-color: #3e8e41;
  }
  
  .aboutpg-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .aboutpg-section li {
    margin-bottom: 10px;
  }
  
  .aboutpg-section a {
    text-decoration: none;
    color: #337ab7;
  }
  
  .aboutpg-section a:hover {
    color: #23527c;
  }

  .aboutpg-section {
    margin-top: 40px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .aboutpg-section h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .aboutpg-section p {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .aboutpg-box {
    transition: transform 0.3s ease; 
    transform: scale(0.9); /* initial scale */
    background-color: #fff;
  }
  
  .aboutpg-box:hover {
    transform: scale(1); 
    background-color: #7ebdf180;
  }

  .our-tools-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .our-tool-box {
    flex-basis: 30%; 
    margin: 15px;
    background-color: #ffffff;
    padding: 15px;
    border: 1px solid #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
    display: flex;
    flex-direction: column; 
    align-items: center;
  }
  
  
  .our-tool-box.text-content {
    text-align: center; 
  }
  
  .our-tool-box h2 {
    font-size: 18px;
    margin-top: 0;
  }
  
  .our-tool-box p {
    font-size: 14px;
    margin-bottom: 15px;
  }

  .our-tool-box:hover {
    background-color: #f48414b9; 
  }

  .our-tool-box img {
    width: 100%; 
    height: 120px; 
    object-fit: contain; 
    max-width: 150px; 
    max-height: 120px; 
    border-radius: 10px 10px 0 0; 
    margin-bottom: 15px; 
  }

  .our-faculty-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
  }
  
  .our-faculty-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .our-faculty-box {
    flex-basis: 40%; /* adjust the width of each box */
    margin: 15px;
    background-color: #ffffff;
    padding: 15px;
    border: 1px solid #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .our-faculty-box:hover {
    background-color: #a5f74786;
  }

  .our-faculty-box img {
    width: 100%; 
    height: 150px; 
    object-fit: contain; 
    margin-bottom: 15px; 
  }

  .our-faculty-box h3 {
    font-size: 15px;
    margin-top: 0;
    font-weight: bold; 
  }
  
  .our-faculty-box p {
    font-size: 14px;
    margin-bottom: 15px;
    line-height: 1.5;
    text-align: justify; 
    padding: 0 10px; 
    margin-top: 10px; 
  }



  .join-us-grid {
    background-color: #ffffff;
    background-image: linear-gradient(to right,#f6a75292, #cd8c6374,   #e173735c, #f5f5dc, #e0ffff, #afeeee);
    padding: 50px;
    text-align: center;
  }
  
  .join-us-grid h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .join-us-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  .join-us-container:hover {
    transform: scale(1.05);
    background-color: #c4ee9386;
}
  
  .join-us-container p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 30px;
  }
  
  .join-us-button {
    background-color: #4CAF50;
    color: #fff;
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .join-us-button:hover {
    background-color: #3e8e41;
  }

/* contact-info.css */
.contact-info {
    max-width: 500px;
    margin: 40px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  
  .contact-info h3 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .info-item {
    display: flex;
    align-items: center;
    margin: 10px;
    font-size: 24px;
    margin-right: 10px;
    color: #666;
  }
  

  .info-item p {
    margin: 0;
  }
  
  .info-item:hover {
    background-color: #f2f2f2;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  
  .info-item:hover i {
    color: #007bff;
  }

  .aboutpg-box-container2 {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: box-shadow 0.3s ease;
}

.aboutpg-box-container2:hover {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
}

.aboutpg-form-box {
  padding: 20px;
}

.aboutpg-form-box h2 {
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
  color: #333;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  color: #666;
}

.form-group input[type="text"],.form-group input[type="email"],.form-group input[type="tel"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-group input[type="text"]:focus,.form-group input[type="email"]:focus,.form-group input[type="tel"]:focus {
  border-color: #aaa;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-group button[type="submit"] {
  width: 100%;
  padding: 10px;
  background-color: #4CAF50;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.form-group button[type="submit"]:hover {
  background-color: #3e8e41;
}

.aboutpg-form-box.form-group:last-child {
  margin-bottom: 0;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .aboutpg-hero {
    height: 200px; 
    background-size: cover; 
    background-position: center; 
  }

  .aboutpg-hero h1 {
    font-size: 24px; 
  }

  .aboutpg-hero p {
    font-size: 14px; 
  }
}

/* Media query for medium-sized screens */
@media (min-width: 768px) and (max-width: 1024px) {
  .aboutpg-hero {
    height: 250px; 
    background-size: 100% 300px; 
  }
}

/* Media query for large screens */
@media (min-width: 1025px) {
  .aboutpg-hero {
    height: 400px; 
    background-size: 100% 400px; 
  }
}


@media (max-width: 768px) {
  .aboutpg-grid {
    display: block; 
    margin-top: 20px; 
  }

  .aboutpg-box {
    width: 100%; 
    margin-bottom: 20px; 
  }

  .aboutpg-box h2 {
    font-size: 18px; 
  }

  .aboutpg-box p {
    font-size: 14px; 
  }

  .aboutpg-box img {
    height: auto; 
  }
}

/* Media query for .join-us-grid */
@media (max-width: 768px) {
  .join-us-grid {
    padding: 30px; 
  }

  .join-us-container {
    padding: 10px; 
  }

  .join-us-grid h1 {
    font-size: 2rem; 
  }

  .join-us-button {
    padding: 10px 20px; 
  }
}

/* Media query for .contact-info */
@media (max-width: 768px) {
  .contact-info {
    max-width: 100%; 
    margin: 20px auto; 
  }

  .info-container {
    flex-direction: column; 
  }

  .info-item {
    margin: 10px 0; 
    font-size: 18px; 
  }
}

@media (max-width: 768px) {
  .our-tools-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .our-tool-box {
    flex-basis: calc(50% - 30px); 
    margin: 15px;
  }
}

@media (max-width: 768px) {
  .our-faculty-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px;
  }
  
  .our-faculty-box {
    flex-basis: calc(100% - 30px); 
    margin: 15px;
  }
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .aboutpg-hero {
    height: 200px;
    background-size: cover;
    background-position: center;
  }

  .aboutpg-hero p {
    font-size: 18px;
  }
}