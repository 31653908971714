.blur-bg {
  width: 100vw;
  height: 100vh;
  background: transparent;
  backdrop-filter: blur(5px);
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
}

.close-btn {
  position: absolute;
  right: 25px;
  top: 10px;
  font-size: 1.8rem;
  font-weight: bold;
  cursor: pointer;
}

.skill-container {
  display: flex;
  flex-direction: column;
  border: 0.2px solid black;
  padding: 25px;
  border-radius: 30px;
  box-shadow: 5px 5px 10px rgb(229, 229, 229);
  width: 40%;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  background: rgb(255, 255, 255);
  z-index: 1000;
}

.skill-container input {
  margin: 10px;
  border-radius: 12px;
  padding: 12px;
  font-size: 1.1rem;
  width: 60%;
  background-color: rgb(242, 239, 239);
}

.skill-container button {
  width: 20%;
  padding: 10px;
  border-radius: 13px;
  cursor: pointer;
  background: #25ced1;
  border: none;
  margin-top: 20px;
  font-size: 1.05rem;
}

.skill-container button:hover {
  background: #1fa9ab;
}