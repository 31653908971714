.admin-container,
.admin-content {
	text-align: left;
	margin: 1vw;
	
}

.admin-content table {
	/* background: linear-gradient(
		to right,
		rgb(255, 175, 83),
		rgb(255, 76, 82),
		rgb(119, 255, 98)
	); */
    font-size: 1.1rem;
    width: 100%;
    margin: 5vw 0;
}

.admin-content thead {
	/* background: #f1f1f1; */
	border: 1px solid #787878;
	border-radius: 13px;
}

.admin-content td {
	padding: 0.6vw;
}

#view-prof-admin {
	background: skyblue;
	cursor: pointer;
	padding: 0.3vw;
	border-radius: 13px;
    border: 2px solid white;
}

#approve-admin {
    background: greenyellow;
	cursor: pointer;
	padding: 0.5vw 0.8vw;
	border-radius: 13px;
    border: 2px solid white;
}

#approve-admin:hover,
#view-prof-admin:hover {
    background: white;
}

@media only screen and (max-width : 500px) {
	.admin-content {
		overflow-x: scroll;
	}
}