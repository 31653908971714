.dashboard {
    margin-top: 8rem;
    margin-left: 8rem;
}
  .notes{
    display: inline-block;
    padding : 2rem;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px #00000020;
  }
  
