/* Apply styles to the container div */

  
  /* Style the heading */
  .heading {
    font-size: 24px;
    color: #333;
  }
  
  /* Style the form */
  .form-making {
    width: 300px;
    margin: 0 auto;
  }
  
  /* Style the input areas */
  .input-area,
  .input-area-second,
  .confirmPassword-area {
    margin: 10px 0;
    text-align: left;
  }
  
  /* Style the labels */
  .label-area,
  .newpassword-label-area {
    display: block;
    font-size: 16px;
    color: #666;
  }
  
  /* Style the input fields */
  .changepasswordarea,
  .newpassword-area,
  .confirmpassword-input-area {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Style the submit button */
  .change-password-button-area {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
  }
  
  /* Hover effect for the submit button */
  .change-password-button-area:hover {
    background-color: #0056b3;
  }
  