.profileevents-profilepage {
  margin-top: -2.5%;
}

.main-profile-page {
  margin-left: 0px;
}

#spinner-prof {
  position: fixed;
  top: 60px;
  left: 48vw;
  height: 100px;
  width: 100px;
}

.profile-heading {
  color: #000;
  font-style: normal;
  line-height: 31px;
  letter-spacing: 0.64px;
  text-transform: capitalize;
  margin-left: 21%;
  margin-top: 4%;
  font-size: 2.6rem;
  font-weight: 800;
  line-height: 31px;
  letter-spacing: 0.02em;
  text-align: left;
}

.profile-data {
  /* margin-left: 23.2%; */
  margin-top: -5.2%;
  border: 2px solid #00000026;
  /* width: 50%; */
  border-radius: 0 0 15px 15px;
}

.profile-data hr {
  /* width: 612px; */
  height: 1.5px;
  background: #00000026;
}

/* ============================================ JOURNEY ============================================= */

.journey-content {
  /* height: 48px;
      width: 486px; */
  /* Allow content to wrap naturally */
  /* width: 612px; */
  /* Set the desired width */
  /* Set margin for space below the "Journey" heading */
  margin-top: 16px;
  overflow: hidden;
  max-height: 48px;
  /* Adjust the max height as needed for 3 lines of text */
  transition: max-height 0.3s ease;
  /* Add a smooth transition */
  font-size: 1.1rem;
}

.journey-content.expanded {
  max-height: none;
  /* Allow content to expand fully when expanded */
}

.lorem-ipsum-dolor.expanded {
  max-height: none;
  /* Allow content to expand fully when expanded */
}

.journey-content .lorem-ipsum-dolor {
  color: transparent;
  font-family: 'Roboto-Regular', Helvetica;
  font-size: 12px;
  font-weight: 400;
  /* left: 0; */
  letter-spacing: 0.48px;
  line-height: 16px;

  margin-bottom: 1px;
}

.text-wrapper {
  color: #181818;
  font-size: 1.1rem;
  margin-bottom: 10px;
  line-height: 24px;
  display: inline-block;
  max-width: 100%;
  text-wrap: wrap;
  white-space: pre-wrap;
}

@media (max-width: 500px) {
  
  .loader {
    display: none;
  }
  .profile-heading {
    margin-top: 50px;
    font-size: x-large;
    margin-left: 35px;
  }
  .text-wrapper {
    color: #181818;
    font-size: 1.1rem;
    margin-bottom: 10px;
    line-height: 24px;
    display: block;
    max-width: 100%;
  }
  .spinner {
    z-index: 100;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.edit-button-text {
  color: #3f75ff;
  /* Set the text color to blue */
  cursor: pointer;
  /* Show a pointer cursor on hover (to indicate interactivity) */
  margin-left: 10px;
  /* Add some space to the left of the text */
  font-size: 14px;
  /* Set the font size to your desired size */
  font-weight: normal;
  /* Remove bold font weight */
  z-index: 0;
}

.long-textarea {
  width: 80%;
  height: 110px;
  border-radius: 5px;
  resize: none;
  padding: 10px;
  vertical-align: top;
  font-size: 1.2rem;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

@media screen and (max-width: 500px) {
  .long-textarea {
    width: 90%;
    margin-top: 10px;
  }
}

.read-more-button {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  color: #3f75ff;
  cursor: pointer;
  margin-inline: auto;
  font-size: 1.2rem;
  width: 100%;
}

.journey {
  /* margin-bottom: 20px; */
  padding: 1.6rem 1rem;
}
.journey > * {
  display: flex;
}
/* ============================================ SKILLS ============================================= */

.skills {
  /* Add margin-top to create space between "Experience" heading and line above it */
  margin-top: 16px;
  padding: 40px 30px 40px 25px;
  /* Adjust the value as needed */
}

.skills h3 {
  /* Optionally, you can add margin-bottom to create space between the heading and content */
  margin-bottom: 16px;
  /* Adjust the value as needed */
}

.skills-content {
  /* Add margin-top to create space between content and line above it */
  margin-bottom: 2px;
  border: 2px solid #108cff;
  border-radius: 10px;
  margin-top: 10px;
  padding: 3px;
  font-size: 1.2rem;
  text-align: center;
  display: flex;
  cursor: pointer;
  gap: 7px;
  width: auto;
  padding: 5px;
  align-items: center;
  justify-content: center;
  /* Adjust the value as needed */
}

.skills-cont {
  display: flex;
  gap: 12px;
  width: 100%;
  flex-wrap: wrap;
  padding: 17px 30px 17px 25px;
  box-sizing: border-box; /* Include padding in the total width and height */
}

.skills-cont > * {
  /* Add your default styles here */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition on hover */
}

.skills-cont > *:hover {
  /* Add your hover styles here */
  background-color: #e6f2ff; /* Example background color on hover */
  color: #2ecc71; /* Example text color on hover */
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  .skills-content {
    padding: 4px;
  }

  .skills-cont {
    display: flex;
    gap: 8px;
    width: 100%;
    flex-wrap: wrap;
    padding: 0px;
  }

  .read-more-button {
    color: #3f75ff;
  }
}

@media (min-width : 600px){
  .job-info{
    width: 65vw;
    /* margin-left: vw; */
  }
  .edu-info{
    width: 65vw;

  }

}
@media (min-width : 500px){
  .job-info{
    width: 65vw;
    /* margin-left: vw; */
  }
  .edu-info{
    width: 65vw;

  }
}
/* ============================================ EDUCATION ============================================= */

.education {
  /* Add margin-top to create space between "Experience" heading and line above it */
  margin-top: 16px;
  padding: 40px 30px 40px 25px;
  /* Adjust the value as needed */
}

.education-content {
  /* Add margin-top to create space between content and line above it */
  margin-bottom: 3rem;
  padding-left: 20px;
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  /* Adjust the value as needed */
}
.education-content #edu-field {
  /* Add margin-top to create space between content and line above it */
  margin-bottom: 2rem;
  padding-left: 0px;
  /* Adjust the value as needed */
}

.education-qualification {
  font-size: 1.3rem;
  margin: 10px;
  font-weight: bolder;
}

.degree {
  font-size: 1.2rem;
  font-weight: bolder;
  margin: 10px;
}

.institute-address {
  margin: 10px;
}
/* ============================================ EXPERIENCE ============================================= */

.experience {
  /* Add margin-top to create space between "Experience" heading and line above it */
  /* margin-top: 16px; */
  padding: 40px 30px 40px 25px;
  /* Adjust the value as needed */
}

.line {
  min-width: 100%;
}

.experience h3 {
  /* Optionally, you can add margin-bottom to create space between the heading and content */
  margin-bottom: 16px;
  /* Adjust the value as needed */
}

.experience-content {
  /* Add margin-top to create space between content and line above it */
  margin-bottom: 2rem;
  padding-left: 30px;
  margin-top: 1.5rem;
  width: 1%;
  display: flex;
  align-items: center;
  /* Adjust the value as needed */
}

.job-title {
  font-size: 1.3rem;
  margin: 10px;
  font-weight: bolder;
}

.jd {
  font-size: 1rem;
  margin: 10px;
  overflow: hidden;
  width: 100%;
}

.experience-content .company {
  font-size: 1.1rem;
  margin: 10px;
  font-weight: bold;
}

.co-location {
  font-size: 1.2rem;
  margin: 10px;
  margin-right: 30px;
}

.add-icon {
  cursor: pointer;
  margin-left: 1%;
  /* Adjust the margin as needed to separate the icon from the heading */
  color: white !important;
  /* Set the icon color to white */
  background-color: #2d8aff;
  /* Set the background color to blue */
  padding: 0px;
  /* Optional: Add padding to the icon container for spacing */
  border-radius: 50%;
  width: 50px;
  /* Optional: Add border-radius for a circular background */
  min-width: 25px;
}

.add-icon:hover {
  opacity: 0.7;
}

.add-icon-container {
  display: flex;
  align-items: center;
  /* gap: 44%; */
  /* Align items vertically */
}

#edit-text {
  /* margin-right: -40%; */
  margin-left: 30%;
  /* Adjust the margin as needed to reduce the gap */
}

.profile-page-content {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-top: 1rem;
}

.date {
  font-size: 1.1rem;
  display: flex;
  gap: 1rem;
  border-bottom: 1px solid rgb(93, 93, 93);
  padding-bottom: 15px;
  width: 30rem;
  margin-left: -8px;
}
@media(min-width:500px){
  .date{
    width: 24rem;
    border-bottom: 1px solid rgb(93, 93, 93);
  }
}
@media (min-width:600px) {
  .date{
    width: 30rem;
    border-bottom: 1px solid rgb(93, 93, 93);
  }  
}
@media (max-width:500px) {
  .date{
    width: 30rem;
    border-bottom: 1px solid rgb(93, 93, 93);
  }  
}


.gap {
  margin-left: 3rem;
}

.date-m {
  color: rgb(66, 66, 66);
}

.more {
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 22%;
  font-size: 1.2rem;
  cursor: pointer;
  color: #3f75ff;
}

.journey > h1 {
  font-size: 30px;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: 0.02em;
}

.experience > h1 {
  font-size: 30px;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: 0.02em;
}

.skills > h1 {
  font-size: 30px;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: 0.02em;
}

.education > h1 {
  font-size: 30px;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: 0.02em;
}

@media only screen and (max-width: 500px) {
  .main-profile-page {
    width: 100%;
    overflow-x: hidden;
    /* right: 10.25rem; */
  }
  .jd {
    font-size: 1rem;
    margin: 10px;
    overflow: hidden;
    width: 200%;
  }
  .journey {
    margin-left: 20px;
  }
  .line {
    margin: auto;
    max-width: 50%;
  }

  .experience-content {
    padding-left: 10px;
    margin-top: 1.5rem;
    width: 1%;
    display: flex;
    align-items: center;
    /* Adjust the value as needed */
  }

  .date {
    width: 100%;
  }

  .education-content {
    margin-bottom: 3rem;
    padding-left: 0px;
    margin-top: 1.5rem;
    margin-right: 40px;
  }
  .profile-page-content {
    margin-left: 0px;
  }

  .company {
    font-size: 1rem;
  }

  .job-title {
    width: 100%;
  }

  .date {
    font-size: 0.8rem;
    display: flex;
    gap: 0.8rem;
    border-bottom: 1px solid rgb(93, 93, 93);
    padding-bottom: 15px;
    margin-inline: 8px;
    width: 125%;
    color: #909090;
  }

  .education-qualification {
    font-size: 1rem;
    margin: 10px;
    font-weight: bolder;
  }

  .degree {
    font-size: 0.8rem;
    font-weight: bolder;
    margin: 10px;
  }
}
@media only screen and (max-width: 500px) {
  .main-profile-page {
    width: 100%;
    /* right: 10.25rem; */
   }
  .jd {
    font-size: 1rem;
    margin: 10px;
    overflow: hidden;
    width: 100%;
  }
  .journey {
    margin-left: 20px;
  }
  .line {
    margin: auto;
    /* max-width: 50%; */
  }

  .experience-content {
    padding-left: 10px;
    margin-top: 1.5rem;
    width: 1%;
    display: flex;
    align-items: center;
    /* Adjust the value as needed */
  }

  .date {
    width: 100%;
  }

  .education-content {
    margin-bottom: 3rem;
    padding-left: 0px;
    margin-top: 1.5rem;
    margin-right: 40px;
  }
  .profile-page-content {
    margin-left: 0px;
  }

  .company {
    font-size: 1rem;
  }

  .job-title {
    width: 100%;
  }

  .date {
    font-size: 0.8rem;
    display: flex;
    gap: 0.8rem;
    border-bottom: 1px solid rgb(93, 93, 93);
    padding-bottom: 15px;
    margin-inline: 8px;
    width: 125%;
    color: #909090;
  }

  .education-qualification {
    font-size: 1rem;
    margin: 10px;
    font-weight: bolder;
  }

  .degree {
    font-size: 0.8rem;
    font-weight: bolder;
    margin: 10px;
  }
}