

.heading {
  font-size: 24px; 
  text-align: center;
  margin: 20px 0; 
  color: #333; 
}


.last-content {
  color: #333; 
  padding: 15px; 
  text-align: center; 
  font-size: 16px;

  border-radius: 5px; 
  margin: 10px 0; 
}


.custom-button {
  background-color: #0074d9; 
  color: #ffffff; 
  font-size: 18px;
  padding: 15px 20px; 
  border: none; 
  cursor: pointer;
  border-radius: 5px; 
  margin-top: 10px; 
}

#percentage-profile {
  position: relative;
  top: 10vh;
  left: 39vw;
  font-size: 1.2rem;
}

#percentage-profile h2 {
  font-size: 2.7rem;
}

/* Hover effect for the button */
.custom-button:hover {
  background-color: #0056b3; 
}

.criterias li {
  font-size: 1.07rem;
}

/* Style for the containing div */
.but {
  text-align: center; 
}

/* Style for the button */
.custom-button {
  background-color: #0074d9;
  color: #ffffff; 
  font-size: 16px; 
  padding: 15px 20px; 
  border: none; 
  cursor: pointer; 
  border-radius: 5px; 
}

.custom-button-active {
  background-color: #0074d9; 
  color: #ffffff; 
  font-size: 16px; 
  padding: 10px 20px; 
  border: none; 
  cursor: pointer; 
  border-radius: 5px; 
  opacity: 0.6;
}

/* Hover effect */
.custom-button:hover {
  background-color: #0056b3; 
}

ul {
  list-style-type: none; 
  padding: 0;
}

li {
  margin: 10px 0;
  /* font-size: 12px; */
  padding-left: 7px; 
  position: relative;
}

.done::before {
  content: "✔"; /* Checkmark (✔) in a green circle for done tasks */
  color: green;
  background-color: lightgreen;
  border-radius: 50%; /* Create a circle with border-radius */
  width: 20px;
  height: 20px;
  display: inline-block;
  text-align: center;
  line-height: 20px; /* Center the checkmark vertically */
  position: absolute;
  margin-left: -20px
  ;
  top: 0;
}

.done {
  color: rgb(
    10,
    231,
    10
  ); 
}

.not-done::before {
  content: "⚠"; 
  color: rgb(246, 255, 0);
  background-color: rgb(234, 180, 63);
  border-radius: 50%; /* Create a circle with border-radius */
  width: 20px;
  height: 20px;
  display: inline-block;
  text-align: center;
  line-height: 20px; /* Center the exclamation mark vertically */
  position: absolute;
  margin-left: -20px;
  
  top: 0;
}

.not-done {
  color: rgb(
    225,
    170,
    16
  ); /* You can change the color for not done tasks as per your preference */
}

@media only screen and (min-width: 750px) {
  .pr-10 {
    padding-right: 2.5rem;
    font-size: 12px;
    width: 190px;
}
}