
.delete-btn {
    
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .delete-btn:hover {
   
    color: rgb(206, 8, 8); 
    font-weight: bold;
    
  }

  .post-1 {
    position: relative;
    padding-right: 40px; 
  }
  
  .delete-post-btn {
    position: absolute;
    top: 0;
    right: 0;
  }


  

@media screen and (max-width: 850px) and (min-width: 500px) {
  
  .post-1 {
    padding-right: 20px; 
  }
}

  