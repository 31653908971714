.event-upcoming2 {
  width: 20%;
  border-radius: 10px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding: 8.5px;
  border: 1px solid gray;
  position: fixed;
  right: 0;
  top: 12vh;
}
.event-list2 {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  gap: 5px;
  scrollbar-width: 0;
  scrollbar-color: transparent;
}

@media only screen and (max-width: 1400px) {
  .event-upcoming2 {
    width: 26%;
    position: fixed;
    right: 3vw;
    top: 12vh;
    height: auto;
  }
}

@media only screen and (max-width: 700px) {
  .event-upcoming2 {
    display: none;
  }
}
@media only screen and (max-width: 1190px) {
  .event-upcoming2 {
    display: none;
  }
}
