.header {
  display: flex;
  z-index: 10;
  align-items: center;
  display: flex;
  gap: 15px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  width: 85%;
}

@media screen and (max-width: 500px) {
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.header span {
  font-weight: bold;
  font-family: "Ubuntu";
  font-size: 20px;
}
