
.box{
border: solid 0.5px;
                    margin: 10px;
                    padding: 5px;
                    border-radius:25px;
                    
                    cursor: pointer;
}

.all-box{
    display:flex;
            flex-wrap: wrap;
            text-align: center;
            align-items: center;
            justify-content: center;
         
            width: 500px;
            position: relative ;
            left: 350px;
}