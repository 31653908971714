.topper {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 50px;
}
.load-main {
  display: flex;
  align-items: center;
  position: relative;
}
.circle-1 {
  position: relative;
  z-index: 10;
  border-radius: 50%;
  display: flex;
  align-items: center;
  height: 40px;
  width: 40px;
  justify-content: center;
  box-shadow: 0px 0px 10px rgb(177, 177, 177);
  background-color: rgb(255, 255, 255); /* Set the background color */
}
.topper .load-main:nth-child(2) {
  left: -10px;
  position: relative;
}
.topper .load-main:nth-child(3) {
  left: -20px;
  position: relative;
}
.topper .load-main:nth-child(4) {
  left: -30px;
  position: relative;
}
.topper .load-main:nth-child(5) {
  left: -40px;
  position: relative;
}
.topper .load-main:nth-child(6) {
  left: -50px;
  position: relative;
}
.topper .load-main:nth-child(7) {
  left: -60px;
  position: relative;
}
.topper .load-main:nth-child(8) {
  left: -60px;
  position: relative;
}

.line-1 {
  height: 10px;
  width: 50px;
  background-color: rgb(255, 255, 255);
  position: relative;
  left: -2px;
  z-index: 0;
  border-left: 0px;
  box-shadow: 0px 0px 10px rgb(201, 201, 201);
}

.color-blue {
  background-color: #108cff;
  color: white;
  font-size: 20px;
  transform: scale(150%);
  font-size: 18px;
}
.bgcolor-blue {
  background-color: #108cff;
}
.hover-current {
  border: 2px solid rgb(6, 192, 192);
}
@media only screen and (max-width: 600px) {
  .topper {
    transform: scale(60%);
    margin-top: 30px;
    margin-left: 20px;
  }
}
