/* Apply styles to the main container */
.form {
  max-width: 502px;
  margin: 0 29vh;
  padding: 12vh 50px 20px;
  font-family: Arial, sans-serif;
  min-width: 45%;
  border-right: 1px solid #00000026;
}

@media screen and (max-width: 700px) {
  .form {
    margin: 0;
    padding-top: 14vh;
  }
}

/* Style the title and subtitle */
.form-top-area h1 {
  font-size: 24px;
  color: #9d9d9d;
  margin-bottom: 10px;
}

/* .form-top-area h2 {
  font-size: 18px;
  color: #666;
  margin-bottom: 20px;
} */

/* Style the star rating area */
.form-star-area {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.form-star-area div {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 5px solid #00000026;
  border-radius: 20px;
  padding: 8px;
  width: 135%;
}

@media screen and (max-width: 500px) {
  .form-star-area div {
    width: 150%;
  }
}

/* Style the "What Went Wrong" section */
.form-what-went-wrong-area {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
}

/* Style the input field in the form area */
.input-in-form-area {
  width: 100%;
  height: 100px;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  background-color: #f5f5f5;
  border: 2px solid #108cff;
  outline: none;
  padding: 10px;
  margin-bottom: 20px;
}

/* Style the "Add" and "Submit" buttons */
.btn-add,
.btn-submit {
  background-color: #108cff;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  border: none;
  outline: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.btn-submit:hover {
  background-color: #0159ac;
}

/* Add spacing between buttons */
.btn-add + .btn-submit {
  margin-right: 0;
}

/* Style MyComponent (adjust styles as needed) */

/* Add more specific styles for the "StarRating" component if needed */

/* Add any additional styles or customizations as required */
