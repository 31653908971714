.confirm-container {
  position: relative;
  left: 12vw;
  display: flex;
  width: calc(100% - 300px);
  padding-top: 10vh;
}

.cnf-prof-pic {
  border-radius: 100%;
  height: 10vw;
  width: 10vw;
  margin-bottom: 3vh;
}

.left-content {
  border-right: 2px solid black;
  padding: 1vw;
  padding-right: 3vw;
}

.left-content > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cnf-user {
  margin-bottom: 20vh;
}

.cnf-line {
  background: #25ced1;
  width: 2px;
  height: 70px;
  position: relative;
  left: 1.8vw;
  top: 2vh;
}
.cnf-line2 {
  background: #d4d4d4;
  width: 2px;
  height: 70px;
  position: relative;
  left: 1.8vw;
  top: -2.1vh;
  margin-bottom: 0;
  padding-bottom: 0;
}

#cnf-circle {
  width: 1.8vw;
  height: 1.8vw;
  margin: 1vw;
  border: none;
  border-radius: 100%;
  background-color: #25ced1;
}

#cnf-round {
  width: 1.8vw;
  height: 1.8vw;
  margin: 1vw;
  border-radius: 100%;
  box-sizing: border-box;
  background: #d9d9d9;
  margin-top: 0;
  padding-top: 0;
}

/* @media screen and (max-width: 500px) {
  .cnf-line {
    background: #25ced1;
    width: 140px;
    height: 5px;
    position: relative;
    left: 10%;
    top: 2vh;
  }
  .cnf-line2 {
    background: #d4d4d4;
    width: 140px;
    height: 5px;
    position: relative;
    left: 50%;
    top: -1.3vh;
    margin-bottom: 0;
    padding-bottom: 0;
  }
} */

.left-content span {
  display: flex;
  gap: 10px;
  align-items: center;
}

.right-content {
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2.5vw;
}

.right-content h2 {
  margin: auto;
  position: absolute;
  top: 3vh;
}

.cnf-video {
  display: flex;
  gap: 30px;
  margin-bottom: 30px;
}

.call-details {
  display: flex;
  background-color: #fd0c0c59;
  /* border: 2px solid rgb(244, 244, 1); */
  border-radius: 15px;
  padding: 1vw;
  flex-direction: column;
  gap: 9vh;
}

.call-date {
  display: flex;
  background-color: #ffb80066;
  /* border: 2px solid rgb(244, 244, 1); */
  border-radius: 15px;
  padding: 1vw;
  flex-direction: column;
  gap: 9vh;
}

.call-details > div,
.call-date > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7vw;
}

.coupon-text {
  width: 100%;
  border-bottom: 1px solid black;
  font-size: 1.05rem;
  border-radius: 0;
}

.pay-details {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 5vh;
}

.session,
.final-amt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px; /* Adjust the gap value as needed */
  width: 100%;
}

.proceed-btn {
  border: none;
  background: #25ced1;
  cursor: pointer;
  font-size: 1.05rem;
  border-radius: 20px;
  padding: 0.5vw 1.3vw;
  margin: auto;
  color: white;
}

#oauth2-btn {
  margin-top: 2vw;
  border-radius: 13px;
  padding: 5px;
  background-color: white;
}

#oauth2-btn:hover {
  opacity: 0.8;
}

.bar-code {
  height: auto;
  max-width: 13vw;
  width: 8vw;
}

@media screen and (max-width: 500px) {
  .qr-container {
    margin-top: 30px;
  }
  .qr-content {
    margin-top: 30px;
  }
  .qr-code {
    margin-top: 20px;
    margin-bottom: 20px;
    flex-direction: column;
    gap: 30px;
  }
  #oauth2-btn {
    margin-bottom: 2vw;
    border-radius: 13px;
    padding: 5px;
    background-color: white;
  }

  .left-content {
    border-right: none;
    padding: 1vw;
    padding-right: 3vw;
  }

  .confirm-container {
    position: relative;
    top: 10px;
    left: 0vw;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 100px;
  }
  .right-content h2 {
    margin: auto;
    top: 47vh;
  }
  .cnf-user {
    margin-bottom: 0px;
  }
  .left-content {
    margin-inline: 20px;
  }
  .right-content {
    margin-inline: 20px;
  }
  .call-details {
    padding: 10px;
    align-items: center;
    justify-content: center;
    /* gap: 10vh; */
  }
  .call-date {
    padding: 10px;
    align-items: center;
    justify-content: center;
    /* gap: 4vh; */
  }
  .cnf-video {
    margin-bottom: 20px;
    gap: 10px;
  }
  .pay-details {
    margin-bottom: 0px;
  }
  .pay-details h3 {
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .proceed-btn {
    margin: 10px 0;
    margin-bottom: 100px;
    padding: 12px;
  }
  .session p {
    font-size: 16px;
  }
  .final-amt p {
    font-size: 16px;
  }
  .coupon-text {
    margin: 20px 0;
  }

  .cnf-prof-pic {
    border-radius: 100%;
    height: 30vw;
    width: 30vw;
    margin-bottom: 2vh;
  }
  .bar-code {
    height: 40vh;
    width: 40vw;
  }
  .proceed-btn {
    padding: 18px;
  }
  .bar-code {
    height: auto;
    max-width: 23vw;
    width: 23vw;
  }
}

@media only screen and (min-width: 1400px) {
  .confirm-container {
    left: 20vw;
  }
}
