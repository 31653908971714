.notify-box {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 35vw;
  margin-left: 0;
  cursor: pointer;
}

.notify-box-content {
  /* position: relative; */
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
  flex-direction: row;
  width: 450px;
  /* height: 65px; */
  /* margin: 10px 0; */
  border-radius: 12px;
  gap: 10px;
  padding-left: 53px;
}

.notify-box-content h3 {
  font-size: 14px;
  font-weight: 500;
  color: #272727;
  font-family: Rounded Mplus 1c;
}

.notify-box-content-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}

.notify-box-content-image img {
  width: 100%;
  height: 100%;
}

.profile-notify {
  position: relative;
  top: 50px;
}

@media screen and (max-width: 400px) {
  .notify-box {
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 80%;
  }

  .notify-box-content {
    padding-inline: 10px;
  }

  .profile-notify {
    position: relative;
    top: 50px;
    bottom: 100px;
  }
}
@media screen and (max-width: 380px) {
  .notify-box {
    width: 81%;
  }
}
