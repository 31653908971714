.partition-plus-panel {
  height: 100vh;
  width: 400px;
  display: flex;
  position: fixed;
  align-items: center;
  left: 70px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 20px rgb(255, 255, 255);
  top: 20px;
  left: 8vw;
  margin-left: 18vw;
}

.partition-s {
  height: 98%;
  width: 2px;
  background-color: rgb(171, 171, 171);
  margin-left: 10px;
}

.search-panel {
  height: 100vh;
  width: 400px;
  padding-top: 55px;
  /* overflow-y: auto; */
  /* height: 100%; */
}
.search-input {
  font-size: "15px";
  padding-left: "30px";
}

.search-head {
  font-size: 2.4rem;
  /* padding-left: 40px; */
  font-weight: 500;
  padding-bottom: 10px;
}

@media (max-width: 400px) {
  .search-panel {
    padding-top: 70px;
    padding-left: 20px;
  }
  .search-head {
    padding-left: 10px;
    padding-bottom: 20px;
    display: none;
  }
}
@media (max-width: 285px) {
  .search-panel {
    width: 294px;
  }
}

.input-div-search {
  width: 110%;
  height: 50px;
  /* background-color: rgb(255, 255, 255); */
  border: 0.1px solid gray;
  box-shadow: 0px 0px 20px rgb(226, 226, 226);
  display: flex;
  border-radius: 10px;
  align-items: center;
}

@media (max-width: 500px) {
  .input-div-search {
    width: 90%;
  }
}

.input-div-search input {
  background-color: transparent;
  width: 100%;
  outline: none;
  border: none;
  font-size: 1.2rem;
  border-radius: 30px;
}

.filter-options-search {
  display: flex;
  width: 100%;
  margin-top: 30px;
  gap: 15px;
  align-items: center;
  font-size: 1rem;
}

.filterss {
  cursor: pointer;
  transition: 0.1s;
  color: gray;
  font-size: 1.1rem;
}

#people-search {
  font-weight: 800;
  color: black;
}

.filterInput > input {
  background: white;
}

.next-partition {
  width: 110%;
  height: 1px;
  background-color: rgb(182, 182, 182);
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}
.filtered-search-results {
  position: relative;
  top: 5vh;
  right:0;
  left: 24vw;
  height: 100%;
  width: 30%;
  background: rgb(255, 255, 255);
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scrollbar-width: thin;
  /* Firefox */
  -ms-overflow-style: none;
  padding-top: 20px;
  padding-bottom: 10px;
  align-items: center;
  /* gap: 10px; */
  border-inline: 1px solid #00000026;
}

.filtered-search-results::-webkit-scrollbar {
  display: none;
  /* Hide the scrollbar for WebKit (Chrome, Safari, etc.) */
}

.colored-filter-btn {
  padding: 10px;
  border-radius: 14px;
  background: #84848433;
  /* color: white; */
  font-size: 1.05rem;
  border: none;
}

@media (max-width: 700px) {
  .filtered-search-results {
    width: 100%;
    top: 70vh;
    left: 0;
    height: auto;
    /* max-height: 45vh; */
    overflow-y: auto; /* Changed from 'scroll' to 'auto' */
    scrollbar-width: thin;
    grid-template-columns: 10px; /* Optional: Customize scrollbar width */
    margin-left: 0;
    align-items: center;
  }
  .no-results-search {
    text-align: center;
    padding: 20px;
  }
  .sresult-1 {
    align-items: center;
}
}

.sresult-1 {
  width: 100%;
  height: auto;
  display: flex;
  background-color: white;
  padding: 10px;
  display: flex;
  gap: 5px;
  cursor: pointer;
  align-items: center;
  border-bottom: 1px solid #00000026;
  /* padding-left: 0; */
 
}

@media (max-width: 400px) {
  .sresult-1 {
    gap: 20px;
  }
}

.sresult-1:hover {
  background: #84848433;
  /* border-radius: 20px; */
}

.searched-user-details {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.searched-user {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}

.filterBY {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-left: 40px;
  margin-bottom: 10px;
}

.filter-btn {
  padding: 10px;
  border-radius: 14px;
  /* background: blue; */
  /* color: white; */
  font-size: 1.05rem;
  border: 1px solid #00000026;
}

.filterInput > input {
  width: 100%;
  margin: 5px;
  padding: 10px;
  font-size: 1rem;
  border-radius: 11px;
  border: 0.1px solid gray;
}

.filter-btn:hover {
  opacity: 0.8;
  cursor: pointer;
}

@media only screen and (max-width: 1400px) {
  .partition-plus-panel {
    margin-left: 30px;
  }
}
@media only screen and (max-width: 500px) {
  .filterBY {
    margin-right: 15px;
    margin-top: 20px;
  }
  .filter-btn {
    padding: 6px 8px;
  }

  .partition-plus-panel {
    margin-left: 0px;
    left: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-inline: auto;
  }

  .partition-plus-panel div {
    padding-inline: 2vw;
    /* overflow-y: auto; */
    /* height: 100%; */
  }

  .search-panel {
    /* width: 100vw; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }
  .filterInput {
    /* position: relative;
    top: -15.5vh;
    left: 15vw;
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    overflow-x: auto;
    scrollbar-width: thin;
    width: 80vw; */
    margin-left: 30px;
    margin-top: 50px;
    width: 130%;
  }
  .filterInput > input {
    position: relative;
    top: 5vh;
    width: 50%;
    margin: 5px;
    padding: 6px;
    font-size: 1rem;
    border-radius: 11px;
    border: 0.1px solid gray;
  }
  .colored-filter-btn {
    opacity: 50%;
    padding: 6px 8px;
    border-radius: 14px;

    /* color: white; */
    font-size: 1.05rem;
    border: none;
  }
}

@media (max-width: 380px) {
  .filtered-search-results {
    width: 100%;
    top: 54vh;
    left: 0;
    height: auto;
    
    overflow-y: auto; 
    scrollbar-width: thin; 
  }
}
@media only screen and (min-width: 700px) and (max-width: 768px) {
  .sresult-1{
    margin-right: 10px;
    gap: 5px;
  }
  .filterInput{
    margin-left: -15px;
  }
  
}
@media only screen and (min-width: 700px) and (max-width: 1078px) {
  .filterBY {
    margin-left: 0%;
  }
  .filtered-search-results{
    gap:10px;
   right: 0;
  }
  .sresult-1{
    right:0;
  }
  .filterInput input{
    width:41%
  }
  
 
}
@media only screen and (min-width: 285px) and (max-width: 350px){
  .input-div-search{
    width: 50%;
  }
  .filter-options-search{
    margin-left: 40px;
    margin-right: auto;
  }
  
}
@media only screen and (min-width: 285px) and (max-width: 350px){
  .filtered-search-results{
   gap:20px;
}
}
@media only screen and (min-width: 700px) and (max-width: 928px){
  .input-div-search{
    width:80%
  }
  .filtered-search-results{
    gap:20px;
 }
}