.blur-bg-prof {
  position: fixed;
  top: 0vh;
  left: 0vw;
  width: 100vw;
  height: 100vh;
  background: transparent;
  backdrop-filter: brightness(30%);
  z-index: 1000;
  /* transform: translate(-50%, -50%); */
}

.close-btn {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 1.8rem;
  font-weight: bold;
  cursor: pointer;
}

.edu-pop-up-container {
  display: flex;
  flex-direction: column;
  border: 0.5px solid black;
  padding: 25px;
  border-radius: 30px;
  box-shadow: 2px 2px 7px 3px gray;
  width: 40%;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  background: white;
  z-index: 1000;
  border: none;
  max-height: 98vh;
}

.edu-pop-up-container input {
  margin: 10px;
  border-radius: 12px;
  padding: 12px;
  font-size: 1.2rem;
  width: 70%;
  background: rgb(239, 238, 238);
}

.edu-pop-up-container button {
  width: 20%;
  padding: 10px;
  border-radius: 13px;
  cursor: pointer;
  background: #25ced1;
  border: none;
  margin-top: 20px;
  font-size: 1.05rem;
}

.edu-pop-up-container button:hover {
  background: #1fa9ab;
}

@media screen and (max-width: 500px) {
  .edu-pop-up-container {
    width: 80%;
  }
}
