.landing-page-container {
  font-family: sans-serif;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.landing-page-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.landing-page-hero {
  margin-top: 50px;
  position: relative;
  width: 100%;
  height: 35vw;
  overflow: hidden;
}

.landing-page-hero img {
  width: 100vw;
  height:100%;
  object-fit: cover;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.arrow-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 24px;
  padding: 10px;
}

.skillop-left-arrow {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  z-index: 1;
  color: #fff;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  color: #fff;
}

.landing-page-boxes {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin-top: -50px; 
  z-index: 2; 
  position: relative; 
  cursor: pointer;
}


.landing-page-box {
  background-color: #fff;
  padding: 30px;
  width: calc(33.33% - 20px); 
  text-align: center;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 10px;
  transition: transform 0.2s, box-shadow 0.2s;
}

.landing-page-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}



.landing-page-boxes h3 {
  margin: 5 px 0;
  color:#fff;
  font-size: 1.5em; /* Increase font size */
  font-weight: bold;
}

.landing-page-box img {
  width: 120px; 
  height: 120px; 
  margin: 0 auto 10px; 
  display: block; 
}



.know-more {
  background-color: #87ceeb; /* Light blue */
}

.news-events {
  background-color: #90ee90; /* Light green */
}

.about-us {
  background-color: #fbac45; /* Orange */
}

.landing-page-content {
  margin-top: 30px;
  padding: 20px;
  text-align: center;
}

.landing-page-content p {
  text-align: justify;
  font-size: 1.2rem;
  line-height: 1.6;
  position: relative;
  margin: 0 auto;
  max-width: 800px;
  padding: 20px;
}

.landing-page-content p::before,
.landing-page-content p::after {
  content: '';
  display: block;
  width: 50%;
  border-bottom: 2px solid #000;
  position: absolute;
  top: 0;
}

.landing-page-content p::before {
  left: 0;
}

.landing-page-content p::after {
  right: 0;
}

.landing-page-content .highlight {
  display: block;
  font-size: 1.4rem;
  text-align: center;
  margin: 20px 0;
  font-weight: bold;
  color: #f67d21;
}

.landing-page-content span {
  display: block;
  font-size: 1rem;
  text-align: center;
  margin: 10px 0;
  color: #555;
}


.get-started-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to left, #87ceeb, #90ee90, #fbac45); /* Gradient background */
  border-radius: 8px;
  padding: 4px;
  width: 180px; /* Fixed width */
  height: 50px; /* Fixed height */
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  margin: 20px auto; /* Center the button */
}

.get-started-btn:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.get-started-btn span {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  padding: 0 16px;
  font-weight: bold;
}

.get-started-btn img {
  width: 20px;
  height: 20px;
}


.why-choose-skillop {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  margin-top: 60px;
}


.left-box {
  width: 50%;
  height: 80vh;
  position: relative;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-left: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; 
}

.left-box h1 {
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  z-index: 2;
  position: relative;
  transition: transform 0.3s, color 0.3s; /* Add transition for smooth effect */
}

.left-box h1:hover {
  transform: scale(1.10); /* Zoom out */
  color: #ddd; /* Slightly lighter color on hover */
}

.left-box video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.666); 
  z-index: 1; 
}


.right-box {
  width: 40%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 20px;
}

 .answer-box-1, .answer-box-2, .answer-box-3 {
  position: relative;
  border-radius: 10px;
  padding: 15px; 
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 35px;
  margin-left: -60px;
  z-index: 3;
}



.answer-box-1 {
  background-color: #90ee90; /* Light green */
  transition: background-color 0.3s, transform 0.3s; /* Add transition for smooth effect */
}

.answer-box-1:hover {
  background-color: #76c76b; /* A slightly darker shade of green */
  transform: scale(1.05); /* Zoom out */
}
.answer-box-1,
.answer-box-2,
.answer-box-3 {
  transition: background-color 0.3s, transform 0.3s; /* Add transition for smooth effect */
}

.answer-box-1 {
  background-color: #90ee90; /* Light green */
}

.answer-box-1:hover {
  background-color: #76c76b; /* A slightly darker shade of green */
  transform: scale(1.05); /* Zoom out */
}

.answer-box-2 {
  background-color: #87ceeb; /* Light blue */
}

.answer-box-2:hover {
  background-color: #6bb7d6; /* A slightly darker shade of blue */
  transform: scale(1.05); /* Zoom out */
}

.answer-box-3 {
  background-color: #fbac45; /* Light orange */
}

.answer-box-3:hover {
  background-color: #e79a3d; /* A slightly darker shade of orange */
  transform: scale(1.05); /* Zoom out */
}

.message-section {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2ab41; 
  padding: 40px;
  font-size: 12px;
}

.message-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.message-box {
  padding: 20px;
  background-color: #ffffff; 
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); 
}

.message-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.message-image {
  max-width: 100%;
  height: auto;
  max-height: 60vh;
}

.message-box h2 {
  font-size: 0.5em;
  margin-bottom: 10px;
  color: #74c20f;

}
.message-box p {
  font-size: 1.2em;
  line-height: 1.6;
  margin-top: 10px;
  color: #161515;
  margin-bottom: 10px;
}



/* News-Event Section */

.newsandevent-section {
  padding: 40px;  
}

.newsandevent-section h2 {
  color: #000000;
  margin-top: 15px;
  text-align: center;
}

.news-grid {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  display: grid;
  gap: 20px;
}

.news-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  background: rgb(246, 243, 243);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.news-column {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.news-card:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
}

.news-card-large {
  width: 100%; 
}

.news-image {
  position: relative;
  height: 180px;
  overflow: hidden;
}

.news-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s ease-in-out;
}

.news-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.815);
  color: #fff;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.news-card:hover .news-overlay {
  opacity: 1;
}

.news-content {
  padding: 20px;
}

.news-content p {
  font-size: 16px;
  margin-bottom: 10px;
}

.news-content span {
  font-size: 14px;
  color: #0371bf;
}

.news-card-large {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
}

.news-card-large .news-image {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.news-card-large .news-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s ease-in-out;
}

.news-card-large .news-content {
  padding: 10px;
}

.news-card-large .news-content p {
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 0%;
}

.news-card-large .news-content span {
  font-size: 14px;
  color: #0371bf;
}


/* Animation */
.news-card {
  animation: fadeIn 0.5s ease-in-out;
}

/* codeclash */
.landing-page-hero img.codeclash{
  height: auto;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}


/* For screens smaller than 768px  */
@media only screen and (max-width: 768px) {
  .landing-page-hero {
    height: 50vw; 
  }

  .landing-page-boxes {
    gap: 10px;
    margin-top: 0; 
  }

  .landing-page-boxes {
    flex-wrap: wrap;
    justify-content: center;
  }

  .landing-page-box {
    width: calc(50% - 20px); 
    margin: 10px 0;
  }

  .left-box {
    width: 100%; 
    margin-left: 0; 
  }

  .right-box {
    width: 100%; 
    padding-left: 0; 
  }

  .message-section{
    margin-top: 40px;
  }

  .message-container {
    grid-template-columns: 1fr; 
  }

}

/* For screens smaller than 480px  */
 @media only screen and (max-width: 480px) {
  .landing-page-hero {
    height: 60vw; 
  }

  .landing-page-box {
    width: 100%; 
    margin: 10px 0;
  }

  .right-box {
    margin-bottom: 40px;
    padding: 10px; 
  }

  .answer-box-1,
  .answer-box-2,
  .answer-box-3 {
    margin-left: 0; 
  }

  .message-section{
    margin-top: 40px;
  }

  .news-grid {
    grid-template-columns: 1fr; 
  }
} 

/* For screens smaller than 768px */
@media only screen and (max-width: 768px) {
  .why-choose-skillop {
    flex-direction: column;
  }
  .left-box {
    width: 100vw;
    height:100vw;
    margin-left: 0;
    order: 1;
  }
  .right-box {
    width: 100%;
    padding-left: 0;
    order: 2;
    margin-bottom: 40px;
  }
  .answer-box-1, .answer-box-2, .answer-box-3 {
    margin-left: 0;
    margin-top: 20px;
  }
  .message-section{
    margin-top: 40px;
  }
}

@media only screen and (min-width: 1300px) {
  .message-box h2 {
    font-size: 2.5em;  
  }
  .message-box p {
    font-size: 1.5em;
  }
}

@media only screen and (min-width: 1500px) {
  .message-box h2 {
    font-size: 2.8em;  
  }
  .message-box p {
    font-size: 1.8em;
  }
}

@media only screen and (min-width: 1900px) {
  .message-box p {
    font-size: 2.2em;
    }
}
