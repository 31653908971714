.bg-follow {
  position: fixed;
  top: 0;
  left: 0;
  backdrop-filter: brightness(30%);
  z-index: 1000;
  height: 100vh;
  width: 100vw;
  color: black;
}

.follow-container {
  color: black;
  border-radius: 13px;
  padding: 1vw 3vw;
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  background: white;
  z-index: 1000;
  width: 30vw;
  box-shadow: 2px 2px 6px 1px;
  max-height: 88vh;
  overflow: auto;
}

.follow-container div {
  border: 2px solid black;
  border-radius: 35px;
  padding: 10px;
  margin: 10px;
  background: white;
  font-size: 1.1rem;
}
.follow-container div:hover {
  cursor: pointer;
  background: rgb(245, 244, 244);
}

#close-follow {
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  .follow-container {
    position: fixed;
    top: 50vh;
    left: 50%;
    width: 70vw;
    height: 40vh;
    padding: 10px;
  }

  .bg-follow {
    backdrop-filter: blur(0px);
  }
}
