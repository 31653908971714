.intro-video-bg {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  backdrop-filter: brightness(30%);
  z-index: 1000;
}
.mystory{
  color: black;
  font-size:xx-large;
  margin-left: auto;
  margin-right: auto;
}

.intro-video-container {
  position: fixed;
  top:50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  border-radius: 15px;
  padding: 1.7vw;
  background: white;
}

.iv-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0vw 0vw 2vw 0vw;
}

.up-btn-video {
  background: green;
  padding: 0.9vw;
  border-radius: 13px;
  font-size: 1.05rem;
  border: none;
  color: white;
}

.iv-btns {
  display: flex;
  justify-content: center;
  gap: 5vw;
  align-items: center;
  margin: 1.5vw 0vw 0.5vw 0vw;
}

.iv-btns > button:hover,
.iv-btns img:hover {
  cursor: pointer;
  opacity: 0.85;
}

.iv-input {
  width: 100%;
  
}
.iv-player {
  aspect-ratio: 16 / 9;
  max-width: 100%;
  
  border-radius: 15px;
  margin-top: 30px;
  margin-bottom: 20px;
  width: 50vw;
  height: 40vh;
  /* margin-top: 50px; */
  
}

.iv-preview > video {
  aspect-ratio: 16 / 9; 
  max-width: 100%;
  border-radius: 15px;
  width: 50vw;
  height: 40vh;
}

.iv-preview {
  text-align: center;
  margin: 1.5vw 5vw;
}

@media screen and (max-width: 500px) {
  .intro-video-container {
    display: flex;
    align-items: center;
    
    flex-direction: column;
    border-radius: 15px;
    padding: 5vw;
    background: white;
    border: 2px solid black;
    width: 90vw;
    left: 50%;
    top: 40%;
    height: 10vh;
    padding-bottom: 5vh;
  }
  .mystory{
    color: black;
    font-size:xx-large;
    margin-left: 26vw;
    margin-right: auto;
    margin-left: auto;
  }

  /* .intro-video-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    padding: 5.7vw;
    background: white;
    height: 50vh;
    left: 35vw;
    top: 35vh;
    border: 2px solid black;
  } */

  .iv-player {
    width: 70vw;
    height: 40vh;
    border-radius: 15px;
    margin-top: 30px;
    margin-bottom: 20px;

  }

  .iv-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0vw 0vw 2vw 0vw;
    width: 100%;
  }

  .up-btn-video {
    background: green;
    padding: 2.9vw;
    border-radius: 13px;
    font-size: 1.05rem;
    border: none;
    color: white;
  }

  .iv-input {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  .intro-video-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 150vh;
    width: 150vw;
    backdrop-filter: none;
    z-index: 1000;
  }
}
