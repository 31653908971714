
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-20px);
    }
    60% {
      transform: translateY(-10px);
    }
  }
  
  .bounce {
    animation: bounce 3s infinite; /* Adjust the duration as needed */
  }
  
  


