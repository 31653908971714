
.chat-list {
  height: 85vh;
  position: relative;
}

@media screen and (max-width: 500px) {
  .chat-list {
    /* top: -10vw; */
    position: relative;
    height: 100%;
    z-index: 10;
  }

.text-chat-prev {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #5f5f5f;
  font-weight: 400;
  font-size: 17px;
  margin-right: 150px;
  margin-bottom: 50px;
}
}