@font-face {
    font-family: 'Product Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans'), local('OpenSans'), url(https://fonts.gstatic.com/s/productsans/v5/HYvgU2fE2nRJvZ5JFAumwegdm0LZdjqr5-oayXSOefg.woff2) format('woff2');
}
body{
    font-family: Product Sans;


}
.container_user{
    margin-top: 75px;
    margin-left: 330px;
    margin-left: auto;
    margin-right: auto;
    
}
.boxes1{
    /* display: flex; */
    /* flex-direction: row; */
    width: 550px;
    /* border: 1px solid black; */
    height: 120px;
    padding: 20px;
    margin: 5px;
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 5px;
    box-shadow: 4px 3px 8px 1px #96969692;

}
.boxes1 img{
    position: relative;
    height: 80px;
    width: 80px;
    top: -33px;


}
.inside-box{
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    padding-left: 10px;
}
.data{
    font-size: 38px;

}
.viewall{
    color:#108CFF ;
    border: none;
    background-color: white;
}
.top_of_reviews{
    display: flex;
    justify-content: space-between;
}
.reviewers{
    width: 500px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 10px;
    margin-top: 10px;
    gap : 4px ;
}
.review{
    width: 550px;
    /* border: 1px solid black; */
    /* height: 250px; */
    padding: 20px;
    margin: 5px;
    border-radius: 5px;
    box-shadow: 4px 3px 8px 1px #96969692;
}
.View_profile{
    border: 1px solid #108CFF;
    background-color: white;
    color: #108CFF;
    height: 25px;
    width: 85px;
    border-radius: 20px;
    font-size: 12px;
}
.user{
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
}

    @media (max-width: 500px){
        .container_user{
            margin-top: 100px;
            margin-left: 5px;
            
            
        }
        .boxes1{
            width: 95%;
        }
        .review{
            width: 95%;
        }
        .reviewers{
            width: 380px;
            /* margin: 10px; */
            margin-top: 10px;
            gap : 1px ;
        }
        .View_profile{
            display: none;
        }
    }

@media (min-width: 500px){
    .container_user{
        
        margin-left: auto;
        margin-right: auto;
        
    }
    .boxes1{
        width: 95%;
    }
    .review{
        width: 95%;
    }
    .reviewers{
        width: 400px;
        margin: 10px;
        margin-top: 10px;
        gap : 4px ;
    }

}
@media (min-width: 600px){
    .container_user{
        
        margin-left: 20px;
        
    }
    .boxes1{
        width: 550px;
    }
    .review{
        width: 550px;
    }
    .reviewers{
        width: 500px;
        margin: 10px;
        margin-top: 10px;
        gap : 4px ;
    }

}
@media (min-width: 700px){
    .container_user{
        
        margin-left: 90px;
        
    }
    /* .boxes1{
        width: 400px;
    }
    .review{
        width: 400px;
    } */

}
@media (min-width: 768px){
    .container_user{
        
        margin-left: 120px;
        
    }
    

}

@media (min-width: 998px){
    .container_user{
        
        margin-left: 200px;
        
    }

}
@media (min-width: 1190px){
    .container_user{
        /* margin-top: 75px; */
        margin-left: 300px;
        
    }

}