.postpopup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 90000000;
  backdrop-filter: blur(8px);
  overflow: scroll;
}

.popup-container {
  position: relative;
  top: 11.6vh;
  left: 24vw;
  z-index: 1;
  border: 2px solid black;
  border-radius: 20px;
  padding: 12px 20px;
  font-size: 1.1rem;
  background: white;
  width: 47%;
  height: 47%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.uploadMedia {
  position: absolute;
  top: 5%;
  left: 10%;
  display: flex;
  gap: 60px;
  padding-bottom: 5px;
  width: 60%;
  height: 20%;
}

.additionalOpt {
  position: absolute;
  top: 88%;
  display: flex;
  gap: 60px;
}

.img{
  max-width: 100px;
  width: 100%;
}

.uploadMedia span,
.additionalOpt span {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
}

.mediaPreview {
  position: absolute;
  top: 25%;
  height: 50%;
  width: 40%;
  text-align: center;
  background: rgb(238, 238, 238);
  border-radius: 20px;
  margin-bottom: 15px;
  background-image: url("/public/post-pop-bg.png");
  background-size: auto;
  background-repeat: no-repeat;
}

.writeSection {
  align-items: center;
  background: #fff;
  border: 2px solid #000;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  padding: 20px 0;
  position: absolute;
  top: 24vw;
  width: 101%;
  z-index: 10000;
}
.writePost {
  border-radius: 10px;
  padding: 17px;
  font-size: 1.2rem;
  font-family: Ubuntu;
  width: 93%;
  height: 24.7vh;
  background: rgb(244, 244, 244);
  border: none;
  background: #f2f2f2;
  resize: none;
}

.uploadMedia span:hover,
.uploadMedia img:hover,
.additionalOpt span:hover,
.writeSection img:hover {
  opacity: 0.8;
  transform: scale(1.1);
  cursor: pointer;
}

.photo-popup {
  position: absolute;
  top: 40%;
  margin-top: 75px;
  z-index: 20;
  width: 100%;
  height: 80px;
  backdrop-filter: blur(10px); 
  display: flex;
  flex-direction: column;
  align-items: center;
  display: none;
  justify-content: center;
  text-align: center;
}

.proceed {
  border: none;
  font-size: 15px;
  padding: 5px 10px 5px 10px;
  background: black;
  color: white;
  border-radius: 10px;
  cursor: pointer;
}

.choose-file{
  font-size: 15px;
}

.image-preview {
  overflow: scroll;
}

.cropped-image {
  object-fit: contain;
  object-position: bottom;
}

@media screen and (max-width: 1380px) {

  .uploadMedia {
    left: 12%;
    gap: 40px;
  }

  .writeSection {
    top: 45vh;
    width: 100%;
    padding: 20px 10px;
  }

  .popup-container {
    top: 11.6vh;
    left: 24vw;
    width: 600px;
    height: 40%;
  }

  .mediaPreview {
    height: 50%;
    width: 40%;
    text-align: center;
    top: 25%;
  }

  .photo-popup {
    top: 35%;
  } 
  
  .proceed , .choose-file{
    font-size: 14px;
  }

}

@media screen and (max-width: 1050px) {
  
  .upload {
    display: flex;
    flex-direction: column;
  }

  .img {
    display: none;
  }

  .post {
    height: 60px;
    width: 40px;
  }

  .uploadMedia {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 90%;
    left: 6%;
  }
  .mediaPreview{
    top: 27%;
    left: 7%;
    width: 40%;
  }
  .photo-popup{
    top: 40%;
  }
}  

@media screen and (max-width: 880px) {
  .popup-container {
    position: relative;
    top: 11.6vh;
    left: 18vw;
    z-index: 1;
    border: 2px solid black;
    border-radius: 20px;
    padding: 12px 20px;
    font-size: 1.1rem;
    background: white;
    width: 85%;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: -63px;
  }
  .uploadMedia {
    position: absolute;
    width: 80%;
    left: 6%;
  }
   
  .mediaPreview {
    position: absolute;
    height: 55%;
    width: 45%;
    text-align: center;
    top: 27%;
    left: 7%;
  }

  .photo-popup {
    top: 45%;
    height: auto;
    gap: 5px;
    width: 90%;
  } 
  
  .choose-file{
    font-size: 12px;
  }
  .proceed {
    font-size: 15px;
  }
}

@media screen and (max-width: 725px) {

  .popup-container{
    height: 40%;
  }

  .mediaPreview{
    width: 55%;
  }
  .photo-popup{
    top: 40%;
  }

  .additionalOpt{
    top: 82%;
    width: 80%;
    justify-content: center;
  }
}

@media screen and (max-width: 495px) {
  .popup-container{
    position: relative;
    left: 22.5%;
    width: 90%;
  }
  .mediaPreview{
    width: 60%;
    height: 40%;
  }
  
  
  .uploadMedia{
    position: absolute;
    gap: 40px;
  }
  .upload{
    font-size: 15px;
  }
}

@media screen and (max-width: 425px) {
  .uploadMedia{
    position: absolute;
    gap: 30px;
  }
}

@media screen and (max-width: 390px) {
  .uploadMedia{
    position: absolute;
    gap: 30px;
  }
  .mediaPreview{
    width: 60%;
    height: 44%;
  }
  .upload{
    font-size: 13px;
  }
  .post{
    height: 45px;
    width: 45px;
  }
}

@media screen and (max-width: 350px) {
  .uploadMedia{
    gap: 20px;
  }
  .choose-file{
    margin-left: 100px;
  }
  .mediaPreview{
    width: 80%;
    height: 40%;
  }
}

@media screen and (max-width: 299px) {
  .uploadMedia{
    gap: 10px;
  }
}
