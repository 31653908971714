.header-8 {
  font-weight: bold;
  display: flex;
  margin-top: 10px;
  justify-content: center;
  font-size: 1.7rem;
  text-align: center;
}

.upload-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.cover {
  height: 17vh;
  width: 30vw;
  background-color: gray;
  z-index: -1;
  border-radius: 5px;
  background-image: url("../../images/b.png");
  background-size: cover;
}

.main-photo {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.main-photo img {
  height: 15vh;
  width: 15vh;
  background-color: rgb(202, 192, 192);
  border-radius: 50%;
  transform: translateY(-50%);
  z-index: 2;
  /* background-image: url('../images/user.png'); */
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-size: cover;
  z-index: 2;
  margin-bottom: -70px;
}

.uploadbtn {
  border: none;
  background-color: rgb(35, 179, 19);
  border-radius: 15px;
  width: 80px;
  color: white;
  height: 40px;
  cursor: pointer;
  margin-top: 2vw;
  /* position: relative;
    top: -50px;
    right: auto; */
}

.next-button-8 {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.next-button-8 button {
  cursor: pointer;
  width: 100px;
  height: 40px;
  background-color: rgb(51, 153, 222);
  color: white;
  border: none;
  border-radius: 10px;
}

.img-uploadpopup {
  padding: 10px;
  position: relative;
  height: 300px;
  max-width: 500px;
  width: 80%;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
}

.skip {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  top: 10px;
}

.skip div {
  height: 40px;
  width: 100px;
  color: rgb(16, 165, 126);
  cursor: pointer;
}

.upload-button {
  border: none;
  border-radius: 10px;
  height: 40px;
  width: 100px;
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
}

.btnCont {
  display: flex;
  place-content: center;
  margin-top: 40px;
  gap: 50px;
  margin-bottom: 10px;
}
/* .btnCont .next2 {
  width: 83px;
  height: 56px;
  color: white;
  border: none;
  background-color: rgb(108, 171, 249);
  border-radius: 16px;
} */

.btnCont .back {
  border: none;
  border-radius: 16px;
  background: #13d755;
  width: 83px;
  height: 56px;
  color: white;
}

.btnCont .next2:hover {
  background-color: #1fa9ab;
  cursor: pointer;
}

.btnCont .back:hover {
  background: #0eb646;
  cursor: pointer;
}

.edit-pic-main-div{
  margin-left: 20vw;
}


@media only screen and (max-width: 600px) {
  .edit-pic-main-div{
    margin-left: 10vw;
  }
  .header-8 {
    margin-top: 30px;
  }

  .upload-img {
    flex-direction: column-reverse;
    margin-top: 20px;
  }

  .cover {
    height: 17vh;
    width: 60vw;  
    height: 150px;
  }

  .next-button-8 button {
    position: relative;
    top: 100px;
  }

  .main-photo {
    gap: 20px;
    position: absolute;
    top: 50vh;
  }

  .main-photo img {
    height: 14vh;
    width: 14vh;
    background-color: rgb(202, 192, 192);
    border-radius: 50%;
    transform: translateY(-50%);
    z-index: 2;
    /* background-image: url('../images/user.png'); */
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-size: cover;
    z-index: 2;
    margin-bottom: -70px;
  }

  .btnCont {
    display: flex;
    place-content: center;
    margin-top: 130px;
    gap: 50px;
    margin-bottom: 10px;
  }

  .img-uploadpopup {
    border: 2px solid black;
    padding: 20px;
  }
}
