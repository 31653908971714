.header-landing {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}
body {
  background-color: rgb(248, 248, 248);
}
.header-landing:hover {
  transform: scale(1.12);
}

.my-name {
  position: relative;
  top: 30px;
  cursor: pointer;
  transition: transform 0.3s linear;
}

.my-name:hover {
  transform: scale(1.1);
}

.dash-nav div {
  padding: 4px 6px;
  margin: -5px;
}

.dash-nav div:hover {
  cursor: pointer;
  background: #25ced1;
  border-radius: 20px;
}

.login-out {
  border: 3px solid black;
}

.active {
  background: #25ced1;
  border-radius: 10px;
}

.notif-active {
  color: black;
  padding: 3px;
  transform: scale(1.1);
  border-radius: 10px;
  box-shadow: 2px 2px 7px #25ced1;
}

.follow-me {
  border: none;
  background: none;
  position: relative;
  top: 10px;
  background-color: rgb(11, 67, 131);
  color: white;
  padding: 2px 5px 2px 5px;
  border-radius: 5px;
  cursor: pointer;
}

.follow-me:hover {
  background-color: rgb(6, 48, 111);
}

.book-me {
  background-color: black;
}

.book-me:hover {
  background-color: white;
  color: black;
  border: 1px solid black;
}

.notification-popup {
  background-color: black;
  top: 0;
  position: absolute;
  height: 100%;
  width: 100px;
}

.nav-landing {
  width: 80%;
  height: 60px;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  border-radius: 62px;
  display: flex;
  margin-top: 30px;
  gap: 20px;
  align-items: center;
  position: relative;
  padding: 10px;
  transition: box-shadow 0.3s linear;
}

.nav-landing:hover {
  box-shadow: 3px 3px 6px;
}

.search-field {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: white;
  backdrop-filter: blur(100px);
  z-index: 10;
}

.search-bar-landing {
  position: relative;
  width: 200px;
  height: 40px;

  background-color: rgb(255, 255, 255);
  border-radius: 28px;
  padding: 0px 10px 0px 10px;
  z-index: 6;
  overflow: hidden;
  transition: 0.5s;
}

.search-icon {
  background: none;
  border: none;
  margin: auto auto;
  margin-left: 7px;
  transition: transform 0.3s ease-in-out;
}

.search-icon:hover {
  transform: scale(1.2);
  cursor: pointer;
}

.filtered-results {
  position: absolute;
  top: -1px;
  left: -1px;
  width: 285px;
  height: 300px;
  background-color: rgb(255, 255, 255);
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  padding-top: 50px;
  /* border: 0.2px solid black; */
  overflow-y: scroll;
  border-radius: 10px;
  outline: 0.2px solid black;
}

.filtered-results div div {
  display: flex;

  align-items: center;
}

.searched-results-partition {
  width: 100%;
  height: 1px;
  background-color: gray;
}

.hidethis {
  display: none;
}

.backblur {
  backdrop-filter: blur(20px);
}

.right-options {
  position: absolute;
  right: 20px;
  display: flex;
  gap: 15px;
}

.home {
  display: flex;
  gap: 5px;
  align-items: center;
  /* background: #25ced1; */
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.home:hover {
  transform: scale(1.1);
  background: #25ced1;
  border-radius: 10px;
  padding: 10px;
}

.notifications {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 4px;
  margin-right: 0px;
}

.notifications i {
  transition: transform 0.3s ease-in-out;
}

.notifications i:hover {
  transform: scale(1.1);
}

.notifications i {
  transition: transform 0.3s ease-in-out;
}

.notifications i:hover {
  transform: scale(1.1);
}

.messages {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
}

.messages:hover {
  transform: scale(1.1);
  background: #25ced1;
  border-radius: 10px;
  padding: 10px;
}

.chat-icon {
  margin-left: 4px;
}

.profile-icon img {
  height: 40px;
  width: 40px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.profile-icon img:hover {
  transform: scale(1.1);
}

.nav-landing-mob {
  display: none;
}

.hamburg-landing {
  display: none;
}
/*code*/
@media only screen and (max-width: 780px){
.posting-on-landing {
    margin-left: -6px;
    top: 0;
    width: 800px;
    padding: 0 25px;
}
.post-1{
  width: 65%;
}
}
@media only screen and (max-width: 843px){
  .posting-on-landing {
    margin-left: -52px;
    transform: translateX(-85px);
    top: 0;
    width: 780px;
  }
  .post-1{
    width: 90%;
  }
}
@media only screen and (max-width: 500px){
.posting-on-landing {
    top: 0;
    width: 800px;
    padding: 0 25px;
    transform: translateX(-100px);
  }
}
@media only screen and (max-width: 400px){
.posting-on-landing {
    top: 0;
    width: 800px;
    padding: 0 25px;
    transform: translateX(-150px);
  }
}
@media only screen and (max-width: 904px) {
  .right-options {
    display: none;
  }
}
  @media only screen and (max-width: 1400px){
.posting-on-landing {
    width: 80%;
}
  }
  @media only screen and (max-width: 1190px){
.posting-on-landing {
    position: relative;
    top: 30px;
    width: 100%;}

  .hamburg-landing {
    z-index: 11;
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 40px;
  }

  .l1,
  .l2,
  .l3 {
    width: 20px;
    content: "";
    height: 0.5px;
    position: relative;
    margin-bottom: 4px;
    top: 4px;
    border: 1px solid rgb(0, 0, 0);
    background: black;
    border-radius: 2px;
    transition: 0.4s;
    cursor: pointer;
  }

  .l1 {
    width: 60%;
  }

  .l2 {
    transition: 0s ease-in-out;
  }

  .l3 {
    width: 60%;
    position: relative;
    left: 0%;
  }

  .nav-landing-mob {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 300px;
    position: absolute;
    top: 0;
    gap: 50px;
    align-items: center;
    background-color: rgba(205, 205, 205, 0.636);
    justify-content: center;
    transition: 0.5s;
    position: fixed;
    backdrop-filter: blur(2px);
    z-index: 10;
  }

  .translatenav {
    transform: translateY(-150%);
    z-index: 20;
  }

  .func1 {
    transform: rotate(45deg);
    width: 100%;
    top: 10px;
    position: relative;
  }

  .func2 {
    visibility: hidden;
  }

  .func3 {
    transform: rotate(-45deg);
    position: relative;
    top: -2px;
    width: 100%;
  }
}

.main-content-landing {
  position: relative;
  top: 50px;
  display: flex;
  left: 250px;
  z-index: 2;
  height: 100%;
  width: calc(100% - 300px);
}



/*----------------------Newly added Profile common and events upcoming -----------*/
.prof-and-events {
  width: 27vw;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: fixed;
  top: 12vh;
  right: 0px;
}

.common-prof {
  width: 90%;
  position: relative;
  /* box-shadow: 0px 0px 20px rgb(201, 200, 200); */
  border-radius: 16px;
  height: 18vw;
  background-image: url("../../../../public/bg.png");
  background-size: 100%;
  background-repeat: no-repeat;
  border: 1px solid rgb(198, 198, 198);
}

.common-prof-info {
  height: 70%;
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: rgb(255, 255, 255);
  border-radius: 114px 0px 14px 14px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.prof-image-common {
  height: 80px;
  width: 80px;
  /* background-image: url('../../images/user3.png'); */
  background-size: cover;
  border-radius: 50%;
  background-color: #ffffff;
}

.user-name {
  font-size: 20px;
  font-weight: 600;
  margin-top: 12px;
  margin-left: 3px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.view-my-prof {
  padding: 8px 2rem 8px 2rem;
  margin-top: 5px;
  border: 2px solid #108cff;
  border-radius: 25px;
  color: #108cff;
  font-size: 18px;
  cursor: pointer;
  transition: 0.2s;
  font-weight: 500;
}

.view-my-prof:hover {
  background-color: #108cff;
  color: white;
}

.event-upcoming {
  width: 90%;
  height: 20vw;
  /* background-color: #e7e5e5; */
  border-radius: 10px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding: 8.5px;
  border: 1px solid rgb(198, 198, 198);
}
.event-list {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  gap: 5px;
  /* overflow-y: scroll; */
  scrollbar-width: 0;
  scrollbar-color: transparent;
}

.join-premium {
  display: inline-block;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1.9px;
  text-align: center;
  text-decoration: none;
  border: none;
  cursor: pointer;
  color: #fff;
  background: linear-gradient(45deg, #f3ce00, #ffaf00);
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  transition: all 0.4s ease-in-out;
}

.join-premium a {
  color: white;
  text-decoration: none;
}

/* Hover effect for the button */
.join-premium:hover {
  background: linear-gradient(45deg, #ffaf00, #ffd700);
}

.event-1 {
  width: 100%;
  border-top: 2px solid gray;
  background-color: rgb(255, 255, 255);
  padding: 5px 0;
}

.event-1:hover {
  cursor: pointer;
  background: rgb(241, 241, 241);
}

/*-------------------- -----------*/

.my-profile-landing {
  height: 400px;
  width: 300px;
  border-radius: 10px;
  background: rgb(255, 255, 255);
  position: relative;
}

.profile-landing-bg {
  height: 150px;

  background-image: url("../../images/b.png");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.profile-img img {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  cursor: pointer;
}

.profile-img {
  height: 100px;
  width: 100px;
  background-color: rgb(231, 231, 231);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(50%);
}

.brief {
  display: flex;
  flex-direction: column;
  height: 250px;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.brief .det {
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.posting-on-landing {
  height: 100vh;
  position: relative;
  top: 3.05vh;
  width: 70%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.user-new-post {
  height: 22vh;
  /* width: calc(80% - 40px); */
  width: 86.4%;
  /* position: relative; */
  /* top: 9vh; */
  background-color: rgb(255, 255, 255);
  /* box-shadow: 2px 2px 13px 1px rgb(192, 192, 192); */
  /* border-radius: 15px; */
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 0px;
  margin-left: auto;
  margin-right: auto;
  padding: 40px 20px;
  transition: all ease-in-out 0.3s;
  border: 1px solid rgb(208, 207, 207);
}

.user-new-post #post-user {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.user-content-post {
  text-wrap: wrap;
  max-height: 120px;
  overflow: hidden;
}
.user-content-post.expanded {
  max-height: none;
  text-wrap: wrap;
}

.user-post-head {
  display: flex;
  gap: 8px;
  align-items: center;
  transition: all ease-in-out 0.3s;
  margin-bottom: 20px;
}

.read-more {
  position: relative;
  left: 0;
  color: rgb(96, 152, 255);
  border: none;
  background: none;
  font-size: 15px;
  cursor: pointer;
}
.post-this {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.not-active {
  display: none;
}

non-act {
  display: none;
}

.user-post-head input {
  width: 30vw;
  border-radius: 30px;
  padding: 12px;
  background-color: rgb(253, 253, 253);
  border: 0.1px solid gray;
  /* box-shadow: 2px 2px 7px rgb(192, 192, 192); */
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  font-size: 1.1rem;
}

.user-post-head input:hover {
  border: 1px solid rgb(136, 136, 136);
  box-shadow: 3px 3px 10px 5px rgb(220, 220, 220);
  width: 31vw;
  /* height: 2.9vh; */
}

.user-post-head img:hover {
  opacity: 0.86;
  transform: scale(1.1);
}

.upload-section {
  display: flex;
  gap: 50px;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

.upload-section div {
  width: 94px;
  height: 32px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.upload-section > div:hover {
  opacity: 0.8;
  transform: scale(1.05);
}

.people-post {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  /* gap: 10px; */
}

@media (min-width: 550px) and (max-width: 843px) {
  .people-post {
    margin-top: 20px;
    width: 100%;
  }
}

#post-user-follow-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.post-1 {
  height: auto;
  width: 90%;
  box-shadow: 2px 2px 10px 2px rgb(199, 199, 199);
  background-color: rgb(255, 255, 255);
  padding: 20px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  gap: 20px;
  border: 1px solid rgb(200, 200, 200);
  margin-left: 45px;
}

#verified-badge {
  width: 21px;
  height: 21px;
}

.posted-by-name {
  display: flex;
  align-items: center;
  gap: 3px;
}

.small-screen-likes-length {
  display: none;
}

@media (max-width: 500px) {
  .likes-name {
    font-size: 0.9rem;
  }
  .small-screen-likes-length {
    display: inline-block;
  }
  .cmts-count {
    font-size: 0.9rem;
  }
}

.share {
  cursor: pointer;
}

.share span:hover {
  font-weight: bold;
}

.create-new-post {
  border: none;
  background: none;
  cursor: pointer;
  /* border: 1px solid gray; */
}

.post-postedby {
  display: flex;
  gap: 10px;
  align-items: center;
}

.post-postedby div {
  display: flex;
  flex-direction: column;
}

.post-postedby img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.img-posted {
  /* height: 300px; */
  width: 100%;
  box-shadow: 0px 0px 10px rgb(234, 234, 234);
  border-radius: 10px;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.posted-img-container {
  overflow: hidden;
  height: 300px;
  cursor: pointer;
}

#iframe-post {
  border-radius: 14px;
  overflow: hidden;
  border: none;
  text-align: center;
  object-fit: scale-down;
}

#iframe-post #document img {
  width: 100%;
}
#iframe-post img {
  width: 100%;
}

.like-count span:hover {
  cursor: pointer;
  transform: scale(1.5);
  font-weight: bold;
}

.like-count:hover {
  cursor: pointer;
  font-weight: bold;
}

.reactions {
  display: flex;
  /* gap: 20px; */
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* margin-top: 10px; */
}

.like-cmts-count {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: -12px 0;
}

.reactions-div {
  position: relative;
  margin-bottom: 10px;
  margin-top: 10px;
}

.like-count {
  display: "flex";
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 6px;
}
.comments {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 6px;
}

.share {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 6px;
}

@media (max-width: 500px) {
  
  .reactions-div {
    margin-top: 0px;
    margin-bottom: 15px;
    margin-left: 10px;
  }

  .reactions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
  }

  .share {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-size: 15px;
  }
  .like-count {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-size: 15px;
  }
  .comments {
    display: flex;
    flex-direction: column;
    font-size: 15px;
  }
}

.like-post {
  display: flex;
  align-items: center;
  gap: 4px;
}

.comments {
  cursor: pointer;
}

.comments:hover {
  font-weight: bold;
}

#comment-to-prof:hover {
  background: rgb(223, 223, 223);
  cursor: pointer;
  
}
#comment-to-prof p {
  overflow-wrap:break-word;
}
.displayComments {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.displayComments input {
  display: block;
  position: relative;
  width: 95%;
  border: 2px solid black;
  border-radius: 20px;
  padding: 8px 12px;
  /* padding-right: 20px; */
  background: rgb(236, 235, 235);
  font-size: 1.07rem;
}

.displayComments button {
  display: block;
  font-size: 1.08rem;
  border: none;
  cursor: pointer;
  font-weight: 600;
  position: relative;
  float: left;
  color: black;
  background-color: rgb(236, 235, 235);
  padding: 8px 20px;
  border: 2px solid black;
  border-radius: 20px;
}

.displayComments button:hover {
  color: rgb(43, 42, 42);
}

.comments-container > div {
  margin-top: 15px;
  border: 2px solid black;
  padding: 8px;
  border-radius: 20px;
}

.comments-container {
  padding: 5px;
}

.comments-container h3 {
  margin-bottom: 5px;
}

.comments-container i {
  cursor: pointer;
  margin-left: 20px;
}

.comments-container i:hover {
  opacity: 0.8;
}

/* /--------------------Chatroom styling--------------------------/  */
.main-content-landing-chat {
  position: relative;
  top: 20px;
  display: flex;
  left: 10vw;
  z-index: 2;
  height: 90vh;
  width: calc(100%);
}

.chat-room {
  width: 89%;
  /* height: 100%; */
  display: flex;
  /* gap: 10px; */
  left: 10vw;
  position: relative;
  margin-top: 8vh;
}

.chatting {
  width: 100%;
  height: 86vh;
  border-radius: 30px;
  background-color: white;
  /* border-inline: 1px solid; */
}

.chat-user-details {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  gap: 5px;
  background-color: rgb(255, 255, 255);
  padding: 10px 20px 10px 20px;
  border-bottom: 1px solid #00000026;
}

.verticall {
  position: absolute;
  right: 30px;
}
.chat-user-img {
  display: flex;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.emoji-picker {
  z-index: 100;
  position: absolute;
  bottom: 8vh;
  right: 0vw;
}
.chat-slider {
  width: 80%;
  /* margin-left: 2vw; */
}
.left-arrow {
  display: none;
}

@media screen and (max-width: 500px) {
  .left-arrow {
    display: block;
  }
  .chat-slider {
    position: fixed;
    top: 5rem;
    right: 0;
    left: 0;
    width: 100%;
    transition: right 0.3s ease-in-out;
    background-color: #fff;
    z-index: 1000;
  }
}

@media only screen and (max-width: 500px) {
  .emoji-picker {
    bottom: 8vh;
    right: -8vw;
  }
  .like-post {
    display: flex;
    flex-direction: column;
  }

  .comments {
    display: flex;
    flex-direction: column;
  }

  .share {
    display: flex;
    flex-direction: column;
  }
}

.chatbox-messages {
  height: 77%;
  max-width: 100%;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer and Edge */
  position: relative;
  /* background: rgb(245, 245, 245); */
  /* background-image: url("/public/chatBG.jpeg"); */
  background-position: center;
  background-size: cover;
  overflow: scroll;
  border-radius: 20px;
  /* border: 2px solid black; */
}

.chatbox-messages::-webkit-scrollbar {
  display: none;
  /* Hide the scrollbar for WebKit (Chrome, Safari, etc.) */
}

.message-send-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  height: 80px;
  width: 100%;
  z-index: 3;
  background-color: white;
}

@media screen and (max-width: 400px) {
  .chat-user-details {
    margin-block: 10px;
  }

  .chatbox-messages {
    height: 75%;
    max-width: 100%;
    border-radius: 10px;
    margin-inline: 10px;
  }

  .message-send-bar {
    margin-block: 5px;
    padding-inline: 15px;
    position: fixed;
    bottom: 0px;
    z-index: 100000;
  }
}

.message-tobe-sent {
  width: 60%;
  /* padding: 3px 16px; */
  background-color: rgb(255, 255, 255);
  border-radius: 30px;
  box-shadow: 0px 0px 5px gray;
  font-size: 1.1rem;
  font-family: ubuntu;
  /* padding: 6px 6px; */
  height: 50px;
  resize: none;
  overflow-y: hidden;
}

.emoji {
  font-size: 25px;
  position: relative;
}

.send-btn {
  border: none;
  color: #108cff;
  font-size: 20px;
  /* padding: 10px; */
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 5px;
}

.send-btn:hover {
  opacity: 0.9;
}

.chat-friends-list {
  z-index: 1;
  height: auto;
  width: 50%;
  background-color: transparent;
  scrollbar-width: 0px;
  /* Firefox */
  overflow-y: auto;
  -ms-overflow-style: none;
  padding: 10px;
  margin-left: 20px;
}

.chat-friends-list > div {
  overflow-y: scroll; /* Enable vertical scrollbar */
  scrollbar-width: none; /* Hide the scrollbar for Firefox */
  position: fixed;
  top: 0rem;
  right: 0rem;
  background: white;
  width: 26%;
  height: 100%;
  z-index: 1;
  border-inline: 1px solid #00000026;
  padding-top: 10vh;
}

/* For Webkit-based browsers */
.chat-friends-list > div::-webkit-scrollbar {
  display: none; /* Hide the scrollbar for Webkit-based browsers */
}

.search-input {
  position: relative;
  border: 0.1px solid gray;
  background: white;
  width: 100%;
  font-size: 1.05rem;
  border-radius: 30px;
  box-sizing: border-box;
  padding: 12px 15px;
  margin: 1.2rem 0px 0rem 0px;
}

.search-img {
  position: absolute;
  top: 13.2vh;
  left: 23vw;
  cursor: pointer;
}

.chat-head {
  /* margin: 20px 0px; */
  margin-top: 40px;
  padding-left: 20px;
  position: fixed;
  top: 0.8vw;
  background: white;
  width: 26%;
  height: 7vh;
  z-index: 100;
  border-bottom: 3px solid gray;
}

.spinner-css {
  position: absolute;
  left: 10vw;
}
@media screen and (max-width: 500px) {
  .chat-friends-list > div {
    overflow-y: auto;
    position: fixed;
    /* top: 9rem; */
    background: white;
    width: 100%;
    min-height: 100vh;
    left: 0;
    margin-bottom: 30vh;
  }

  .search-input {
    width: 320px;
    margin-top: 10vh;
    position: fixed;
  }

  .search-img {
    position: absolute;
    left: 71vw;
    top: 11.5vh;
    cursor: pointer;
  }
  .chat-head {
    /* display: none; */
    top: 10vw;
    width: 100%;
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 0;
    margin-bottom: 0;
    height: 6vh;

    /* background: red; */
  }

  .chat-friends-list {
    margin-top: 0px;
  }

  .message-tobe-sent {
    height: 16px;
    padding-top: 10px;
  }
}

.user-section {
  display: flex;
  align-items: center;
  padding-top: 1.7rem;
  padding-bottom: 2.7rem;
  padding-right: 1.7rem;
  padding-left: 1rem;
  height: 20px;
  gap: 12px;
  /* background-color: rgb(255, 255, 255); */
  border-radius: 13px;
}

.user-section:hover {
  /* background-color: #8484841a; */
  cursor: pointer;
}

.active-chat {
  background: rgba(255, 184, 0, 0.2);
}

.chats-user {
  padding: 20px 1px;
  border-bottom: 0.5px solid #00000026;
  /* margin-top: 100px; */
}
.chats-user:hover {
  background-color: #8484841a;
  /* margin-top: 100px; */
}

#chat-user-pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.partition-users {
  width: 100%;
  height: 1px;
  background-color: gray;
}

@media only screen and (max-width: 1150px) {
  .chat-room {
    flex-direction: column;
    width: 80%;
    align-items: center;
    left: -5vw;
  }

  .chatting {
    width: 40%;
    height: 90vh;
  }

  .chat-friends-list {
    width: 95%;
    overflow-y: visible;
    min-height: 50vh;
    padding-bottom: 30vh;
  }
}

@media only screen and (max-width: 1400px) {
  .main-content-landing-chat {
    left: 80px;
    width: 100%;
  }
  .chatting {
    width: 100%;
  }
  /* .chat-room {
    gap: 50px;
  } */
}
@media only screen and (max-width: 900px) {
  .main-content-landing-chat {
    left: 40px;
    width: 100%;
  }
  .chat-room {
    width: 80%;
    left: -19px;
  }
  .chat-friends-list {
    width: 90%;
    margin-bottom: 100px;
  }
}
@media only screen and (max-width: 500px) {
  .chats-user {
    padding: 10px;
    border-bottom: 0.5px solid #00000026;
    /* margin-top: 100px; */
  }
  .main-content-landing-chat {
    position: relative;
    top: 80px;
    left: 20px;
  }

  .chat-room {
    left: 0px;
    width: 90%;
    gap: 50px;
  }
}

/* /chatbox-------------/  */
.message-right {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  padding: 20px;
  color: rgb(0, 0, 0);
}

.message-left {
  color: #000000;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 20px;
}

.message-left p {
  border-radius: 20px 20px 20px 0px;
  background: #ffffff;
  padding: 8px 8px 8px 8px;
  max-width: 60%;
  box-shadow: 2px 2px 7px 2px rgb(201, 201, 201);
  font-size: 1.1rem;
}

.message-right p {
  max-width: 60%;
  border-radius: 20px 20px 0px 20px;
  background-color: #5f5f5f1a;
  padding: 8px 8px 8px 8px;
  box-shadow: 2px 2px 7px 2px rgb(201, 201, 201);
  font-size: 1.1rem;
}

/*-----------------------------*/
.search-field-mob {
  position: relative;
  width: 80%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  display: none;
}

.search-bar-landing-mob {
  width: 70%;
  height: 30px;
}

.filtered-results-mob {
  position: relative;
  top: -25px;
  height: 200px;
  width: 35%;
  display: flex;
  flex-direction: column;
  width: 55%;
  padding: 10px;
  background-color: rgb(255, 255, 255);
  z-index: 5;
  margin-left: auto;
  margin-right: auto;
  display: none;
  border-radius: 5px;
  transition: 0.4s ease-in-out;
  animation: appear 0.5s ease-in-out;
}

.searched-results-mob {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.searched-results {
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 20px;
  font-size: 20px;
}

.searched-results img {
  height: 40px;
  width: 40px;
}

.partition-mob {
  width: 100%;
  height: 1px;
  background-color: gray;
}

@keyframes appear {
  0% {
    height: 0px;
  }

  100% {
    height: 200px;
  }
}

@media only screen and (max-width: 700px) {
  .search-field-mob {
    display: flex;
  }

  .search-field {
    display: none;
  }

  .filtered-results-mob {
    display: flex;
  }
}

/*------------*/
@media only screen and (max-width: 1400px) {
  .main-content-landing {
    left: 60px;
  }
  .posting-on-landing {
    width: 80%;
  }
  .user-new-post {
    width: 86.4%;
  }
  .prof-and-events {
    right: 70px;
  }
}
@media only screen and (max-width: 1190px) {
  .main-content-landing {
    width: 100%;
    position: relative;
    left: 50%;
    display: flex;
    flex-direction: column;
    transform: translateX(-50%);
    align-items: center;
    left: calc(50% + 30px);
  }
  .posting-on-landing {
    width: 100%;
    position: relative;
    top: 30px;
  }
  .event-upcoming {
    width: calc(100% - 40px);
  }
  .common-prof {
    width: 100%;
  }
  .user-new-post {
    height: auto;
  }
  .prof-and-events {
    width: 70%;
    max-width: 1000px;
    min-width: 200px;
    position: absolute;
    left: 50%;
    /* transform: translateX(-50%); */
    display: none;
  }
  .prof-image-common {
    height: 60px;
    width: 60px;
  }
}
@media only screen and (max-width: 500px) {
  .main-content-landing {
    left: 50%;
    /* transform: translateX(-50%); */
    top: 0%;
    max-height: 100%;
  }
  .post-1 {
    margin: 0px;
    margin-bottom: 100px;
    /* margin-top: 10px; */
    width: 100%;
    padding-bottom: 30px;
    padding-inline: auto;
    border-radius: 0px;
    /* box-shadow: none; */
    /* background-color: rgba(128, 128, 128, 0.237); */
    border-radius: 15px;
  }

  .prof-and-events {
    margin-top: 50px;
  }
}

.mobile-panel {
  display: flex;
  position: fixed;
  /* width: calc(100% - 20px); */
  width: 100%;
  background-color: rgb(255, 255, 255);
  height: 60px;
  /* padding: 10px; */
  padding-top: 15px;
  justify-content: center;
  /* box-shadow: 0px 0px 5px rgb(128, 128, 128); */

  bottom: 0;
  border-top: 1px solid #00000033;
  z-index: 100;
  display: none;
}

.more-vertical-options {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  z-index: 100;
  /* backdrop-filter: blur(8px); */
}

.more-vertical-options-new {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  height: 100vh;
  width: 65vw;
  left: 0;
  bottom: 0;
  z-index: 10000000000000;
  background-color: #ffffff;
  border-right: 1px solid #00000033;
  padding-top: 80px;
  padding-left: 20px;
  overflow-y: auto; /* Enable scrolling if content exceeds height */
  /* Other styles */
  animation: poppingdelay 0.2s ease-in-out; /* If you have an animation */
}

@keyframes poppingdelay {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.inside-panel {
  display: flex;
  width: 85%;
  position: relative;
  justify-content: space-between;
}



.post-on-mob-btn {
  background-color: rgb(14, 121, 121);
  position: absolute;
  left: 45%;
  display: flex;
  place-content: center;
  align-items: center;
  border-radius: 50%;
  transform: translateX(-20%);
  color: white;
  transform: translateY(-50%);
  width: 60px;
  height: 60px;
  font-size: 60px;
}
.mobile-panel-options {
  font-size: 28px;
  color: rgb(0, 0, 0);
}
.color {
  color: black;
}

.notColor {
  color: rgba(0, 0, 0, 0.667);
}


/* Mobile view */
@media only screen and (min-width: 700px) and (max-width: 840px) {
  .post-1{
    margin-left: 38vw;
  }
}
@media only screen and (max-width: 768px) {
  .mobile-panel {
    display: flex;
  }
  .posting-on-landing {
    top: 0px;
  }
  .post-1 {
    margin-bottom: 5px;
    width: 65%;
  }
  .cross {
    margin-top: 30px;
  }
  .common-prof {
    display: none;
  }
  .event-upcoming {
    display: none;
  }
  .user-new-post {
    display: none;
  }
  .img-posted {
    width: 100%;
    position: relative;
  }
  .back-posr-arr {
    position: relative;
    left: 35px;
    border-radius: 100%;
    box-shadow: 2px 2px 10px 1px black;
  }
  .next-post-arr {
    position: relative;
    right: 35px;
    border-radius: 100%;
    box-shadow: 2px 2px 10px 1px black;
  }
}

@media only screen and (max-width: 500px) {
  .people-post {
    padding-top: 80px;
    padding-bottom: 80px;
    width: 85%;
    /* margin-left: 39px; */
    margin-top: 0px;
  }
}
@media only screen and (max-width: 380px) {
  .people-post {
    padding-top: 80px;
    padding-bottom: 80px;
    width: 58%;
    margin-left: 42px;
  }
  .post-1{
    width: 74%;
  }
}
@media only screen and (max-width: 400px) {
  .people-post {
    padding-top: 80px;
    padding-bottom: 80px;
    width: 58%;
    margin-left: 42px;
    margin-top: 0px;
  }
}
@media only screen and (max-width: 365px) {
  .people-post {
    padding-top: 80px;
    padding-bottom: 80px;
    width: 56%;
    margin-left: 42px;
    margin-top: 0px;
  }
}
@media only screen and (max-width: 285px) {
  .people-post {
    padding-top: 80px;
    padding-bottom: 80px;
    width: 44%;
    margin-left: 42px;
    margin-top: 0px;
  }
}
@media only screen and (min-width: 1192px) {
  .people-post {
    padding-top: 20px;
    padding-bottom: 80px;
    width: 100%;
    margin-left: 22px;
    margin-top: 0px;
  }
}

.cmts-count{
 gap:10px;
 }
 @media (min-width: 285px) and (max-width: 364px) {
  .cmts-count{
    gap:10px;
    margin-right: 15px;
  }
 }