.people-post2 {
  margin-top: 2rem;
  margin-left: 20vw;
  width: 50%;
}

.people-post2 h1 {
  padding: 40px;
}

.mypostareainprofile{
  width: 90%;
}



@media screen and (max-width: 500px) {
  .people-post2 {
    width: 70%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 50px;
  }

  .people-post2 h1 {
    width: 200%;
    text-align: center;
  }
  .mypostareainprofile{
    width: 141%;
  }
}
@media screen and (max-width: 365px) {
  .people-post2 h1 {
    width: 170%;
    text-align: center;
  }
}

@media screen and (max-width: 285px) {
  .people-post2 h1 {
    width: 190%;
    text-align: center;
  }
  .mypostareainprofile{
    width: 150%;
  }
}
